import React from 'react';
import Paragraph from '@components/structure/Paragraph';

const PlaidServiceUnavailableModal = () => {
  return (
    <Paragraph>
      We encountered an issue and the service is down. Please try again later.
    </Paragraph>
  );
};

export default PlaidServiceUnavailableModal;
