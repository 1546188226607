import { extendTheme } from 'native-base';

const theme = extendTheme({
  components: {
    Radio: {
      defaultProps: {
        colorScheme: 'darkBlue',
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: 'tertiary',
      },
    },
    Toast: {
      defaultProps: {
        // It seems that is not working for the moment. It will be solved in the future by nativebase
        placement: 'bottom-right',
      },
    },
  },
});

export default theme;
