import React, { useState, FC } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import DataTable from '@components/table/Table';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { PAYMENTS_ACTION_TYPES } from '@sagas/actions/PaymentsActions';
import PaymentHistoryTableRow from './PaymentHistoryTableRow';
import LoadingSpinner from '@components/LoadingSpinner';
import {
  shortColMobile,
  lightGrey,
  defaultGreyColor,
  alignCenter,
  tableItemStyle,
} from '@styles/app';
import { isMobileBrowser } from '@helpers/appHelper';

const PaymentHistoryTableWrapper: FC<React.ReactNode> = ({
  children,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
  const payments = useSelector(
    (state: RootStateOrAny) => state.payments.payments,
  );

  React.useEffect(() => {
    dispatch({
      type: PAYMENTS_ACTION_TYPES.FETCH_PAYMENTS,
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (typeof payments.size !== 'undefined') {
      setShowLoadingSpinner(false);
    }
  }, [payments]);

  return (
    <View>
      {showLoadingSpinner ? (
        <LoadingSpinner />
      ) : payments.size > 0 ? (
        <DataTable.Table>
          <DataTable.TableHeader>
            {children}
            <DataTable.TableItem
              style={tableItemStyle}
              testID="payment-history-headers"
            >
              Charge
            </DataTable.TableItem>
            <DataTable.TableItem
              style={
                isMobileBrowser ? [alignCenter, shortColMobile] : tableItemStyle
              }
              testID="payment-history-headers"
            >
              Payment
            </DataTable.TableItem>
            <DataTable.TableItem
              style={tableItemStyle}
              testID="payment-history-headers"
            >
              Balance
            </DataTable.TableItem>
          </DataTable.TableHeader>
          {payments.map(payment => (
            <PaymentHistoryTableRow
              key={`${payment.record_id}-${payment.record_type}`}
              payment={payment}
            />
          ))}
        </DataTable.Table>
      ) : (
        <Text
          style={styles.noPaymentsMessage}
          testID="rent-pay-ledger-no-payments"
        >
          You have no payments at this moment!
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  dataTableHeader: {
    backgroundColor: lightGrey,
  },
  noPaymentsMessage: {
    color: defaultGreyColor,
    textAlign: 'center',
    padding: 10,
  },
});

export default PaymentHistoryTableWrapper;
