import React, { useEffect } from 'react';
import { View, Keyboard, Platform } from 'react-native';
import { Input } from 'native-base';
import {
  applyCurrencyFormat,
  filterCurrency,
  centsToDollars,
  stringAmountToNumber,
} from '@helpers/currencyHelper';
import { CurrencyInputFCInterface } from '@ownTypes/InputTypes';

const CurrencyInput: React.FC<CurrencyInputFCInterface> = ({
  placeholder = 'Amount',
  value,
  handleOnBlur,
  testID,
}): JSX.Element => {
  const [formattedValue, setFormattedValue] = React.useState('0');

  const handleChangeText = (inputText: string) => {
    if (Platform.OS === 'ios' && inputText[inputText.length - 1] === ',') {
      inputText = inputText.replace(/,$/, '.');
    }
    setFormattedValue(inputText.replace(/[^$,0-9.]/g, ''));
  };

  useEffect(() => {
    setFormattedValue(filterCurrency(centsToDollars(value)));
  }, [value]);

  const changeValueFormat = () => {
    let newValue = formattedValue;

    if (/^-?\d+$/.test(newValue)) {
      newValue = filterCurrency(formattedValue);
    } else {
      newValue = applyCurrencyFormat(formattedValue);
    }

    handleOnBlur(stringAmountToNumber(newValue, false, true));
    setFormattedValue(newValue);
    Keyboard.dismiss();
  };

  return (
    <View>
      <Input
        blurOnSubmit
        placeholder={placeholder}
        value={formattedValue}
        size="lg"
        keyboardType="decimal-pad"
        onChangeText={handleChangeText}
        onBlur={changeValueFormat}
        testID={testID}
      />
    </View>
  );
};

export default CurrencyInput;
