import React from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { StoreInterface } from '@ownTypes/StoreType';

type useVersionCheckType = {
  storeUrl: string;
  versionChecked: boolean;
  requiredUpdate: boolean;
  recommendedUpdate: boolean;
};

export const storeUrl = platform =>
  platform === 'ios'
    ? 'itms-apps://itunes.apple.com/app/apple-store/id1608509369'
    : platform === 'android'
    ? 'https://play.google.com/store/apps/details?id=com.stessa.tenantportal'
    : '';

const compareVersionNumbers = (
  version: string,
  installedVersion: string,
): boolean => {
  const [major, minor] = version.split('.');
  const [installedMajor, installedMinor] = installedVersion.split('.');

  if (major > installedMajor || minor > installedMinor) {
    return true;
  }

  return false;
};

export const useVersionCheck = (): useVersionCheckType => {
  const installed_app = useSelector((state: StoreInterface) => state?.app);
  const minimal_app = useSelector(
    (state: StoreInterface) => state?.user.ldFlags.minimal_app_version,
  );

  const [state, setState] = React.useState({
    storeUrl: storeUrl(Platform.OS),
    versionChecked: false,
    requiredUpdate: false,
    recommendedUpdate: false,
  });

  React.useEffect(() => {
    if (minimal_app) {
      const { build: installedBuild, version: installedVersion } =
        installed_app;
      const { build, version } = minimal_app;

      setState(prev => {
        const requiredUpdate = compareVersionNumbers(version, installedVersion);
        const recommendedUpdate = !requiredUpdate && build > installedBuild;

        return {
          ...prev,
          versionChecked: true,
          requiredUpdate,
          recommendedUpdate,
        };
      });
    }
  }, [installed_app, minimal_app]);

  return state;
};
