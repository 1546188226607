import React from 'react';
import { StyleSheet, Text } from 'react-native';
import {
  Alert as NBAlert,
  HStack,
  VStack,
  Box,
  IconButton,
  CloseIcon,
} from 'native-base';
import { h6, col_12 } from '@styles/app';
import { AlertFCInterface } from '@ownTypes/ComponentsTypes';
import { ALERT_STATUS } from '@ownTypes/AlertTypes';

const Alert: React.FC<AlertFCInterface> = ({
  state,
  setState,
}): JSX.Element => {
  React.useEffect(() => {
    return () => {
      setState(null);
    };
  }, [setState]);

  if (!state?.description) {
    return null;
  }

  const { description, title = '', status = ALERT_STATUS.INFO } = state;

  return (
    <NBAlert status={status}>
      <VStack style={styles.wrapper}>
        <HStack style={styles.header}>
          <HStack space={2} style={styles.title}>
            <NBAlert.Icon />
            <Text
              testID="alert-title"
              style={title ? styles.titleMd : styles.titleSm}
            >
              {title ? title : description}
            </Text>
          </HStack>
          <IconButton
            variant="unstyled"
            icon={<CloseIcon size="3" />}
            onPress={() => setState(null)}
          />
        </HStack>

        {title && (
          <Box pl="6" testID="alert-description">
            {description}
          </Box>
        )}
      </VStack>
    </NBAlert>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    space: 0,
    flexShrink: 1,
    ...col_12,
  },
  header: {
    flexShrink: 1,
    space: 2,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    flexShrink: 1,
    alignItems: 'center',
  },
  titleMd: {
    fontSize: h6,
    fontWeight: '500',
  },
  titleSm: {
    fontWeight: 'normal',
  },
});

export default Alert;
