import { call, put as storePut } from 'redux-saga/effects';
import Request from '@services/api/index';
import {
  API_SUBSCRIPTION,
  API_SUBSCRIPTION_GET,
  API_SUBSCRIPTION_WITHDRAWAL_DATE_OPTIONS,
} from '@store/config/apiUrls';
import { APIResolveResponse } from 'types/ApiTypes';
import {
  SubscriptionUpdatePayloadType,
  SubscriptionResponseType,
  SubscriptionStatus,
} from 'types/reducerTypes/SubscriptionTypes';
import {
  setSubscription,
  setSubscriptionError,
  setSubscriptionWithdrawalDateOptions,
} from '@reducers/UserReducer';
import { ObjectType } from '@ownTypes/GenericTypes';
import { TRANSACTION_TYPES } from '@ownTypes/TransactionTypes';

const { put, get, post } = Request();

export function* handleGetSubscription({ payload }: { payload: { balance?: number }}) {
  try {
    let url = API_SUBSCRIPTION_GET;
    if (typeof payload?.balance === 'number') {
      url += `?balance=${payload?.balance}`;
    }
    const response: APIResolveResponse<SubscriptionResponseType> = yield call(
      () => get(url),
    );

    if (response?.data?.account_id) {
      yield storePut(
        setSubscription({
          type: TRANSACTION_TYPES.NOOP,
          data: response?.data,
        }),
      );
    }
  } catch (err) {
    yield storePut(setSubscriptionError(err.message));
  }
}

export function* handleCreateSubscription({
  data: { payload },
  callback,
}: {
  data: {
    payload: SubscriptionUpdatePayloadType;
  };
  callback: (args?: any) => void;
}) {
  try {
    const response: APIResolveResponse<SubscriptionResponseType> = yield call(
      () => post(`${API_SUBSCRIPTION}`, payload),
    );

    if (response?.data?.account_id) {
      yield storePut(
        setSubscription({
          type: TRANSACTION_TYPES.CREATED,
          data: response?.data,
        }),
      );
      if (typeof callback === 'function') {
        yield callback(response.data);
      }
    }
  } catch (err) {
    yield storePut(setSubscriptionError(err.message));
  }
}

export function* handleUpdateSubscription({
  data: { id, payload },
  callback,
}: {
  data: {
    id: number;
    payload: SubscriptionUpdatePayloadType;
  };
  callback: (args?: any) => void;
}) {
  try {
    const response: APIResolveResponse<SubscriptionResponseType> = yield call(
      () => put(`${API_SUBSCRIPTION}/${id}`, payload),
    );
    if (response?.data?.account_id) {
      yield storePut(
        setSubscription(
          response.data.status === SubscriptionStatus.ACTIVE
            ? {
                type: TRANSACTION_TYPES.UPDATED,
                data: response.data,
              }
            : null,
        ),
      );
      if (typeof callback === 'function') {
        yield callback(response.data);
      }
    }
  } catch (err) {
    yield storePut(setSubscriptionError(err.message));
  }
}

export function* handleSubscriptionWithdrawalDateOptions() {
  try {
    const response: APIResolveResponse<ObjectType> = yield call(() =>
      get(`${API_SUBSCRIPTION_WITHDRAWAL_DATE_OPTIONS}`),
    );
    yield storePut(setSubscriptionWithdrawalDateOptions(response?.data));
  } catch (err) {
    console.error(err);
  }
}
