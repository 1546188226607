import { defaultGreyColor } from 'assets/styles/app';
import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { BasicComponentFCInterface } from 'types/ComponentsTypes';

const BoldText: React.FC<BasicComponentFCInterface> = ({
  style,
  children: text,
  testID = null,
}) => {
  return (
    <Text testID={testID} style={[styles.container, style]}>
      {text}
    </Text>
  );
};

const styles = StyleSheet.create({
  container: {
    color: defaultGreyColor,
    fontWeight: '700',
  },
});

export default BoldText;
