import React, { useState } from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  Platform,
} from 'react-native';
import { useSelector } from 'react-redux';
import { defaultGreyColor, h6 } from '@styles/app';
import useHandleLogout from 'hooks/useHandleLogoutCTA';
import { StoreInterface } from 'types/StoreType';

const LogoutButton: React.FC = (): JSX.Element => {
  const handleLogout = useHandleLogout();
  const storedToken = useSelector((state: StoreInterface) => state.user.token);
  const appErrors = useSelector((state: StoreInterface) => state.app.errors);
  const [autoLogout, setAutoLogout] = useState(false);
  const testID = storedToken ? 'logout-button' : 'login-button';

  //TODO move 401 error -> logout somewhere else
  React.useEffect(() => {
    if (appErrors.length) {
      const latestError = appErrors[appErrors.length - 1];
      if (latestError?.status === 401) {
        //handle logout just for the 1st 401 status, sometimes there are more and it will break auth0 clearsession
        if (!autoLogout) {
          handleLogout();
          setAutoLogout(true);
        }
      }
    }
  }, [appErrors, autoLogout, handleLogout]);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={handleLogout}
        testID={Platform.OS !== 'android' ? testID : null}
      >
        <Text
          style={styles.logout}
          testID={Platform.OS === 'android' ? testID : null}
        >
          {storedToken ? 'Logout' : 'Login'}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  logout: {
    fontSize: h6,
    color: defaultGreyColor,
    alignSelf: 'center',
  },
});

export default LogoutButton;
