import React from 'react';
import { StyleSheet } from 'react-native';
import PageSubtitle from './PageSubtitle';
import { h5 } from '@styles/app';
import { PageSubtitleSecondaryFCInterface } from '@ownTypes/ComponentsTypes';

const PageSubtitleSecondary: React.FC<PageSubtitleSecondaryFCInterface> = ({
  children: childComponent,
  testID,
}): JSX.Element => {
  return (
    <PageSubtitle style={styles.text} testID={testID}>
      {childComponent}
    </PageSubtitle>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: h5,
    fontWeight: 'normal',
  },
});

export default PageSubtitleSecondary;
