import { datadogRum } from '@datadog/browser-rum';

export const initializeDataDog = () => {
  datadogRum.onReady(() => {
    datadogRum.init({
      applicationId: process.env.DATADOG_APPLICATION_ID,
      clientToken: process.env.DATADOG_CLIENT_TOKEN,
      env: process.env.RUNNING_ENV,
      sampleRate: 100,
      version: process.env.CI_COMMIT_SHORT_SHA,
      premiumSampleRate: 100,
      trackFrustrations: true,
      service: 'tenant-portal-web',
      defaultPrivacyLevel: 'mask-user-input',
      site: 'datadoghq.com',
    });

    datadogRum.startSessionReplayRecording();
  });
};

export const setDataDogUser = user => datadogRum.setUser(user);
