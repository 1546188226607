import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Modal,
} from 'react-native';
import React, { useRef, useState } from 'react';
import { FC } from 'hoist-non-react-statics/node_modules/@types/react';
import {
  tooltipArrowDimensions,
  tooltipBoxDimensions,
  defaultButtonTextColor,
  smallFontSize,
  tooltipBackgroundColor,
  defaultSectionPadding,
} from '@assets/styles/app';
import {
  TOOLTIP_POSTION,
  TooltipInterface,
  ComponentCurrentPosition,
} from 'types/TooltipTypes';

const Tooltip: FC<TooltipInterface> = ({
  children,
  label,
  tooltipPosition,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [currentBoxHeight, setCurrentBoxHeight] = useState<number>(0);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  const childPositionReference = useRef(null);

  const updateCurrentPosition = async (): Promise<any> => {
    return childPositionReference.current.measure((_px, _py, h, _w, fx, fy) => {
      switch (tooltipPosition) {
        case TOOLTIP_POSTION.BOTTOM:
          setPosition({
            y: fy + h + 10,
            x: fx + tooltipArrowDimensions.width / 2,
          });
          break;
        case TOOLTIP_POSTION.LEFT:
          setPosition({
            y: fy + tooltipArrowDimensions.width / 2,
            x: fx - tooltipArrowDimensions.width - defaultSectionPadding,
          });
          break;
        case TOOLTIP_POSTION.RIGHT:
          setPosition({
            y: fy + tooltipArrowDimensions.width / 2,
            x: fx + tooltipArrowDimensions.width * 2 + defaultSectionPadding,
          });
          break;
        default:
          setPosition({
            y: fy - tooltipArrowDimensions.width - defaultSectionPadding,
            x: fx + tooltipArrowDimensions.width / 2,
          });
          break;
      }
    });
  };

  const boxPosition = (): ComponentCurrentPosition => {
    switch (tooltipPosition) {
      case TOOLTIP_POSTION.BOTTOM:
        return {
          top: position.y + tooltipArrowDimensions.width / 2,
          left:
            position.x - tooltipBoxDimensions.width / 2 + defaultSectionPadding,
        };
      case TOOLTIP_POSTION.LEFT:
        return {
          top:
            position.y -
            currentBoxHeight / 2 +
            tooltipArrowDimensions.height / 2,
          left:
            position.x -
            tooltipBoxDimensions.width +
            tooltipArrowDimensions.width / 2,
        };
      case TOOLTIP_POSTION.RIGHT:
        return {
          top:
            position.y -
            currentBoxHeight / 2 +
            tooltipArrowDimensions.height / 2,
          left: position.x + tooltipArrowDimensions.width / 2,
        };
      default:
        return {
          top:
            position.y - currentBoxHeight + tooltipArrowDimensions.height / 2,
          left:
            position.x - tooltipBoxDimensions.width / 2 + defaultSectionPadding,
        };
    }
  };

  const toggleTooltip = () => {
    updateCurrentPosition().then(() => {
      setVisible(true);
    });
  };

  const RenderedTooltip = () => {
    return (
      <Modal visible={visible} transparent animationType="none">
        <TouchableOpacity
          style={styles.overlay}
          onPressIn={() => {
            setVisible(false);
          }}
        >
          <View
            style={[
              styles.arrow,
              {
                top: position.y,
                left: position.x,
              },
            ]}
          />
          <View
            onLayout={event => {
              setCurrentBoxHeight(event.nativeEvent.layout.height);
            }}
            style={[styles.textbox, boxPosition()]}
          >
            <Text style={styles.text}>{label}</Text>
          </View>
        </TouchableOpacity>
      </Modal>
    );
  };

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      updateCurrentPosition,
    );
    return () => subscription?.remove();
  });

  return (
    <TouchableOpacity onPress={toggleTooltip} ref={childPositionReference}>
      {visible && <RenderedTooltip />}
      {children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  textbox: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: tooltipBackgroundColor,
    position: 'absolute',
    borderRadius: 3,
    padding: defaultSectionPadding,
    width: tooltipBoxDimensions.width,
  },

  arrow: {
    height: tooltipArrowDimensions.height,
    width: tooltipArrowDimensions.width,
    transform: [
      {
        rotateZ: '135deg',
      },
    ],
    backgroundColor: tooltipBackgroundColor,
    position: 'absolute',
  },

  overlay: {
    height: '100%',
    width: '100%',
  },

  text: {
    fontSize: smallFontSize,
    color: defaultButtonTextColor,
    alignSelf: 'center',
  },
});

export default Tooltip;
