import { takeLatest, ForkEffect } from 'redux-saga/effects';
import { SUBSCRIPTION_ACTION_TYPES } from './actions/SubscriptionActions';
import {
  handleGetSubscription,
  handleCreateSubscription,
  handleUpdateSubscription,
  handleSubscriptionWithdrawalDateOptions,
} from './handlers/SubscriptionHandler';

export default function* subscriptionSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    SUBSCRIPTION_ACTION_TYPES.GET_SUBSCRIPTION,
    handleGetSubscription,
  );
  yield takeLatest(
    SUBSCRIPTION_ACTION_TYPES.CREATE_SUBSCRIPTION,
    handleCreateSubscription,
  );
  yield takeLatest(
    SUBSCRIPTION_ACTION_TYPES.UPDATE_SUBSCRIPTION,
    handleUpdateSubscription,
  );
  yield takeLatest(
    SUBSCRIPTION_ACTION_TYPES.GET_SUBSCRIPTION_WITHDRAWAL_DATE_OPTIONS,
    handleSubscriptionWithdrawalDateOptions,
  );
}
