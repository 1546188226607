import React, { useRef, useState, useCallback } from 'react';
import { Animated, View, StyleSheet } from 'react-native';
import PageSubtitle from '@components/PageSubtitle';
import CloseButton from '@components/buttons/CloseButton';
import Portal from '@burstware/react-native-portal';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMakePaymentOverlay,
  setSideModalToggle,
} from '@reducers/AppReducer';
import { StoreInterface } from '@ownTypes/StoreType';
import { isMobileBrowser } from '@helpers/appHelper';
import { clearPaymentError } from '@reducers/PaymentsReducer';

const SideModal: React.FC<{ title: string; children: JSX.Element }> = ({
  title,
  children,
}) => {
  const dispatch = useDispatch();
  const slideInRight = useRef(new Animated.Value(0)).current;
  const sideModal = useRef();
  const isSideModalClicked: {
    current: boolean;
  } = useRef();
  const [modalShow, setModalShow] = useState(false);
  const modalToggle: boolean = useSelector(
    (state: StoreInterface) => state.app.sideModalToggle,
  );
  let handleClose: any = null;

  const handleMouseDown = useCallback(event => {
    const modal = sideModal.current as HTMLObjectElement;
    isSideModalClicked.current = modal.contains(event.target);
  }, []);

  const clearErrors = useCallback(() => {
    dispatch(clearPaymentError());
  }, [dispatch]);

  const closeEvent: (event: MouseEvent) => void = useCallback(
    event => {
      if (event) {
        const modal = sideModal.current as HTMLObjectElement;
        const isModalElement = modal.contains(event.target as HTMLElement);
        const isAlertShown = document.getElementById('alertModal');
        const isBaseModalOpen = document.getElementById('baseModal');
        if (!isModalElement && !isSideModalClicked.current && !isAlertShown && !isBaseModalOpen) {
          handleClose();
        }
      }
    },
    [handleClose, isSideModalClicked],
  );

  handleClose = useCallback((): void => {
    // Not using state because, when listening to event it is encapsulated in closeEvent callback.
    // Also with dependency is not working.
    const isOverlay = document.getElementById('loading-overlay');
    if (!isOverlay) {
      document.removeEventListener('click', closeEvent);
      document.removeEventListener('mousedown', handleMouseDown);
      dispatch(setSideModalToggle(false));
    }
  }, [dispatch, closeEvent, handleMouseDown]);

  React.useEffect(() => {
    if (modalToggle) {
      setModalShow(true);
      Animated.timing(slideInRight, {
        toValue: 1,
        duration: 700,
        useNativeDriver: false,
      }).start(state => {
        if (state.finished) {
          document.addEventListener('click', closeEvent);
          document.addEventListener('mousedown', handleMouseDown);
        }
      });
    } else {
      Animated.timing(slideInRight, {
        toValue: 0,
        duration: 700,
        useNativeDriver: false,
      }).start(state => {
        if (state.finished) {
          dispatch(setMakePaymentOverlay(false));
          handleClose();
          setModalShow(false);
          clearErrors();
        }
      });
    }
  }, [
    modalToggle,
    closeEvent,
    handleClose,
    slideInRight,
    handleMouseDown,
    clearErrors,
    dispatch,
  ]);

  const transformStyle = {
    transform: [
      {
        translateX: slideInRight.interpolate({
          inputRange: [0, 1],
          outputRange: [
            isMobileBrowser ? window.innerWidth : window.innerWidth / 2,
            0,
          ],
        }),
      },
    ],
  };

  return (
    <Portal>
      {modalShow && (
        <Animated.ScrollView
          style={[styles.container, transformStyle]}
          nativeID="sideModal"
          ref={sideModal}
          testID="slide-modal"
        >
          <View style={styles.sideModalHeader}>
            <PageSubtitle testID="slide-modal-title">{title}</PageSubtitle>
            <CloseButton handleClose={handleClose} testID="slide-modal-close" />
          </View>
          <View>{children}</View>
        </Animated.ScrollView>
      )}
    </Portal>
  );
};

const styles = StyleSheet.create<{
  container: any;
  sideModalHeader: any;
}>({
  container: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: isMobileBrowser ? '100%' : '50%',
    height: '100%',
    backgroundColor: 'white',
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.26,
    padding: 20,
  },
  sideModalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
});

export default SideModal;
