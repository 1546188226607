import { setLDFlags, setUserData } from '@reducers/UserReducer';
import { call, put } from 'redux-saga/effects';
import Request from '@services/api/index';
import { API_USERS_ME } from '@store/config/apiUrls';
import { APIResolveResponse } from 'types/ApiTypes';
import { UserType } from 'types/reducerTypes/UserTypes';

const { get } = Request();

export function* fetchLoggedUser() {
  try {
    const response: APIResolveResponse<UserType> = yield call(() =>
      get(API_USERS_ME),
    );
    if (response?.data) {
      yield put(setLDFlags(response.data.flags));
      delete response.data.flags;
      yield put(setUserData(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}
