import React from 'react';
import Paragraph from '@components/structure/Paragraph';
import BoldText from '@components/structure/BoldText';
import { Box, Image } from 'native-base';
import { Text, StyleSheet } from 'react-native';
import { dangerColor, defaultGreyColor, defaultMarginSmall, defaultSectionPadding } from '@assets/styles/app';

const plaidManualLinkingConfirmationScreen = require('@images/plaidManualLinkingConfirmationScreen.png');

const PlaidUserInstructionsModal = () => {
  return (
    <>
      <BoldText style={styles.boldText}>Please read carefully.</BoldText>
      <Paragraph>1. You will be asked to enter the 2 deposit amounts that were added to your account for verification purposes.</Paragraph>
      <Paragraph>2. After you have entered both deposits, make sure you keep clicking on the Continue button until the flow ends.</Paragraph>
      <BoldText>
        <Text style={styles.importantText}>Do not click </Text>
        on the X button on the screen below or close the browser window.
      </BoldText>
      <Box style={styles.imageWrapper}>
        <Image
          size="80"
          resizeMode="contain"
          source={plaidManualLinkingConfirmationScreen}
          alt="Image with confirmation step into Plaid manual linking flow"
        />
      </Box>
      <Paragraph>3. If you exit out of the screen, the verification process will be incomplete and you will not be able to make a rent payment.</Paragraph>
      <Paragraph>4. In case you accidentally exited out, you will have to wait for 24 hours before you can re-connect the same bank account.</Paragraph>
    </>
  );
};

const styles = StyleSheet.create({
  boldText: {
    marginBottom: defaultMarginSmall,
  },
  importantText: {
    color: dangerColor,
  },
  imageWrapper: {
    alignItems: 'center',
    backgroundColor: defaultGreyColor,
    marginVertical: defaultMarginSmall,
    padding: defaultSectionPadding,
  },
});

export default PlaidUserInstructionsModal;
