import React, { FC } from 'react';
import { Text, View, StyleSheet, ScrollView } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import PrimaryButton from '@components/buttons/PrimaryButton';
import {
  button,
  h5,
  h6,
  defaultFontWeight,
  defaultGreyColor,
  defaultMargin,
} from '@styles/app';
import { useSelector } from 'react-redux';
import { StoreInterface } from 'types/StoreType';
import { Errors } from '@lang/Errors';

interface RouteParams {
  error?: string;
  errorDescription?: string;
}

const ErrorPage: FC = () => {
  const navigation = useNavigation<any>();
  const route = useRoute();
  const storedToken = useSelector((state: StoreInterface) => state.user.token);
  const routeParams: RouteParams = route.params ?? {};

  const showError = () => {
    if (route.params && routeParams.error && routeParams.errorDescription) {
      return routeParams.errorDescription;
    } else {
      return Errors.default;
    }
  };

  const navigateToLogin = () => {
    const ROUTES = require('navigation/routes').default;
    navigation.navigate(ROUTES.LOGIN.name);
  };

  return (
    <ScrollView testID="error-page-view">
      <View style={styles.container}>
        <Text style={styles.titleError} testID="error-page-title">
          An error has occurred.
        </Text>
        <Text style={styles.textError} testID="error-page-text">
          {showError()}
        </Text>
        {!storedToken && (
          <PrimaryButton
            style={button}
            title="Login"
            handlePress={navigateToLogin}
          />
        )}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: defaultMargin,
  },
  titleError: {
    fontSize: h5,
    fontWeight: defaultFontWeight,
    color: defaultGreyColor,
  },
  textError: {
    fontSize: h6,
    marginTop: defaultMargin,
    marginBottom: defaultMargin,
    fontWeight: defaultFontWeight,
  },
});

export default ErrorPage;
