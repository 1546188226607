import React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';

const BaseOverlay: React.FC = (): JSX.Element => {
  return (
    <View
      style={styles.loadingOverlay}
      testID="loading-overlay"
      nativeID="loading-overlay"
    >
      <ActivityIndicator style={styles.activityIndicator} size="large" />
    </View>
  );
};

const styles = StyleSheet.create({
  loadingOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffffe0',
    zIndex: 3,
    elevation: 3,
    flex: 1,
  },
  activityIndicator: {
    flex: 1,
  },
});

export default BaseOverlay;
