import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { defaultGreyColor, h5 } from '@styles/app';
import { PageSubtitleFCInterface } from '@ownTypes/ComponentsTypes';

const PageSubtitle: React.FC<PageSubtitleFCInterface> = ({
  style,
  children: childComponent,
  testID,
}): JSX.Element => {
  return (
    <Text style={[styles.text, style]} testID={testID}>
      {childComponent}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: h5,
    color: defaultGreyColor,
    marginVertical: 10,
  },
});

export default PageSubtitle;
