import React from 'react';
import { View, StyleSheet } from 'react-native';
import { lightGrey } from '@styles/app';

const HorizontalSeparator: React.FC = (): JSX.Element => {
  return <View style={styles.separator} />;
};

const styles = StyleSheet.create({
  separator: {
    borderWidth: 1,
    borderColor: lightGrey,
    marginHorizontal: 10,
  },
});

export default HorizontalSeparator;
