import React, { FC } from 'react';
import { Text, View, StyleSheet, Image } from 'react-native';
import PrimaryButton from '@components/buttons/PrimaryButton';
import {
  button,
  h5,
  h6,
  defaultGreyColor,
  defaultMargin,
} from '@styles/app';
import navigateTo from '@helpers/navigateTo';
import { APP_UPDATE } from '@lang/AppUpdate';

const logo = require('@images/logo.png');

const AppUpdate: FC = ({ route }) => {
  const { storeUrl } = route.params;

  return (
    <View style={styles.container}>
      <Image style={styles.logo} source={logo} alt="Stessa" testID="logo" />

      <View style={styles.infoWrapper}>
        <Text style={styles.title} testID="title">
          {APP_UPDATE.title}
        </Text>
        <Text style={styles.body} testID="body">
          {APP_UPDATE.body}
        </Text>
      </View>

      <PrimaryButton
        style={{ ...button, wrapper: styles.buttonWrapper }}
        title={APP_UPDATE.buttonText}
        handlePress={() => navigateTo(storeUrl)}
        testID="button"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    maxHeight: '80%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  buttonWrapper: { alignSelf: 'stretch' },
  logo: {
    width: 200,
    height: 57,
    resizeMode: 'contain',
  },
  infoWrapper: {
    alignItems: 'center',
  },
  title: {
    fontSize: h5,
    color: defaultGreyColor,
  },
  body: {
    fontSize: h6,
    marginTop: defaultMargin,
    marginBottom: defaultMargin,
    color: defaultGreyColor,
  },
});

export default AppUpdate;
