import { createNavigationContainerRef } from '@react-navigation/native';
import { ObjectType } from '@ownTypes/GenericTypes';

export const navigationRef = createNavigationContainerRef();

export const navigate = (name: string, params: ObjectType): void => {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
};
