import { configureStore } from '@reduxjs/toolkit';
import userReducer from '@reducers/UserReducer';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import appReducer from '@reducers/AppReducer';
import paymentsReducer from '@reducers/PaymentsReducer';
import accountsReducer from '@reducers/AccountsReducer';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    user: userReducer,
    payments: paymentsReducer,
    accounts: accountsReducer,
    app: appReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;
