import React from 'react';
import { List } from 'immutable';
import { ACCOUNT_STATUS } from '@ownTypes/AccountStatusTypes';
import { AccountType } from '@ownTypes/reducerTypes/AccountTypes';

const useSelectableAccounts = (
  accounts: Immutable.List<AccountType>,
): List<AccountType> => {
  const [selectableAccounts, setSelectableAccounts] = React.useState<
    List<AccountType>
  >(List());

  React.useEffect(() => {
    if (!accounts) {
      return;
    }

    const list = accounts.filter(
      account =>
        account?.verification_status !== ACCOUNT_STATUS.PENDING_VERIFICATION &&
        account?.verification_status !== ACCOUNT_STATUS.LOGIN_REQUIRED,
    );

    setSelectableAccounts(list);
  }, [accounts]);

  return selectableAccounts;
};

export default useSelectableAccounts;
