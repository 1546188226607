import FormGroup from 'components/FormGroup';
import BasicSelect from 'components/inputs/BasicSelect';
import { englishOrdinalSuffix } from '@helpers/appHelper';
import React, { useState } from 'react';
import { StoreInterface } from 'types/StoreType';
import { useDispatch, useSelector } from 'react-redux';
import { SUBSCRIPTION_ACTION_TYPES } from 'store/sagas/actions/SubscriptionActions';
import { BasicSelectFCInterface } from 'types/ComponentsTypes';

const WithdrawalDate = ({ selectedWithdrawDay, handleChange }): JSX.Element => {
  const {
    user: { data: userData, paymentSubscriptionWithdrawalDateOptions: options },
  } = useSelector((state: StoreInterface) => state);
  const dispatch = useDispatch();
  const [dueDays, setDueDays] = useState<BasicSelectFCInterface['items']>([]);

  React.useEffect(() => {
    if (!options) {
      dispatch({
        type: SUBSCRIPTION_ACTION_TYPES.GET_SUBSCRIPTION_WITHDRAWAL_DATE_OPTIONS,
      });
    }
  }, [options, dispatch]);

  React.useEffect(() => {
    if (options && dueDays.length === 0) {
      const keys = Object.keys(options);
      const mapped = keys.map(key => ({
        label: key,
        value: String(options[key]),
      }));

      setDueDays(mapped);
    }
  }, [options, dueDays]);

  return (
    <FormGroup
      label="Withdrawal Day"
      labelTestId="withdrawalDates-date-select-label"
      inputInfo={`Your Rent is due on the ${englishOrdinalSuffix(
        userData?.rent_due_date,
      )} of every month`}
    >
      <BasicSelect
        items={dueDays}
        selected={selectedWithdrawDay}
        handleChange={handleChange}
        placeholder="Select withdrawal day"
        testID="autopay-withdrawal-day-select"
      />
    </FormGroup>
  );
};

export default WithdrawalDate;
