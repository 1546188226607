import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { defaultGreyColor, h6 } from '@styles/app';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';

const NoAccountsInfo: React.FC = () => {
  return (
    <View style={styles.container}>
      <Icon style={styles.icon} name="money-check-alt" />
      <Text style={styles.text}>Please add a payment method.</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: defaultGreyColor,
    fontSize: h6,
    marginRight: 5,
  },
  text: {
    color: defaultGreyColor,
    fontSize: h6,
  },
});

export default NoAccountsInfo;
