import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { h4, defaultGreyColor } from '@styles/app';
import { WelcomeMessageFCInterface } from '@ownTypes/RentPayTypes';

const WelcomeMessage: React.FC<WelcomeMessageFCInterface> = ({
  name,
  isBalancePaid,
}): JSX.Element => {
  return (
    <View>
      <Text style={styles.welcomeMessage} testID="rent-pay-status">
        {isBalancePaid
          ? "Great Job! You're all paid up."
          : `Hi ${name}, rent is due soon!`}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  welcomeMessage: {
    fontSize: h4,
    color: defaultGreyColor,
    marginVertical: 40,
  },
});

export default WelcomeMessage;
