import { RoutesType } from '@ownTypes/NavigationTypes';

const ROUTES: RoutesType = {
  RENT_PAY: {
    url: '/rent-pay',
    name: 'Rent Pay',
    component: require('@views/RentPay').default,
  },
  MAKE_PAYMENT: {
    url: '/make-payment',
    name: 'Make Payment',
    component: require('@views/MakePayment').default,
  },
  PAYMENT_CONFIRMATION: {
    url: '/payment-confirmation',
    name: 'Payment Confirmation',
    component: require('@views/MakePayment/PaymentConfirmation').default,
  },
  AUTOPAY: {
    url: '/edit-autopay',
    name: 'Edit Autopay',
    component: require('@views/AutoPay').default,
  },
  AUTOPAY_CONFIRMATION: {
    url: '/update-confirmation',
    name: 'Autopay Confirmation',
    component: require('@views/AutoPay/Confirmation').default,
  },
  LOGIN: {
    url: '/login',
    name: 'Login',
    component: require('@views/Login').default,
  },
  ERROR_PAGE: {
    url: '/error',
    name: 'Error',
    component: require('@views/ErrorPage/').default,
  },
  APP_UPDATE: {
    url: '/app-update',
    name: 'App Update',
    component: require('@views/AppUpdate').default,
  },
};

export default ROUTES;
