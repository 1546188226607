/* eslint-disable react-native/no-inline-styles */
import React, { createContext, useContext, useState } from 'react';
import { Modal } from 'native-base';
import { StyleSheet } from 'react-native';
import { defaultColor, headerGrey, lineGrey } from 'assets/styles/app';
import { SafeAreaView } from 'react-native-safe-area-context';
import PrimaryButton from './buttons/PrimaryButton';
import DefaultButton from './buttons/DefaultButton';
import { BaseModalProps, BaseModalContextValues } from '@ownTypes/BaseModalTypes';

const initalState: BaseModalContextValues = {
  showModal: ({}) => null,
  hideModal: () => null,
  isShowing: false,
};

const ModalContext = createContext(initalState);

export const useModalContext = () => useContext(ModalContext);

export const BaseModalProvider: React.FC = ({ children }): JSX.Element => {
  const [isShowing, setIsShowing] = useState(false);
  const [modalProps, setModalProps] = useState<BaseModalProps>(null);

  const showModal = (props: BaseModalProps) => {
    setIsShowing(true);
    if (Object.keys(props).length > 0) {
      setModalProps(props);
    }
  };

  const hideModal = () => {
    setIsShowing(false);
    setModalProps(null);
  };

  const renderModal = () => {
    const {
      body = null,
      title = '',
      testID = null,
      size = 'md',
      closeOnOverlayClick = false,
      cancelButtonTitle = 'Cancel',
      confirmationButtonTitle = 'Ok',
      showCancelButton = false,
      handleConfirm = () => null,
    }: BaseModalProps = { ...modalProps };

    if (!title && !body) {
      return null;
    }

    return (
      <SafeAreaView nativeID="baseModal">
        <Modal testID={testID} isOpen={isShowing} onClose={() => setIsShowing(false)} size={size} closeOnOverlayClick={closeOnOverlayClick}>
          <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header
              style={styles.alertHeader}
              testID="base-modal-title"
            >
              { title }
            </Modal.Header>
            <Modal.Body style={styles.alertBody} testID="base-modal-body">
              { body }
            </Modal.Body>
            <Modal.Footer style={[styles.alertFooter, showCancelButton && { justifyContent: 'space-between' }]}>
              {showCancelButton && (
                <DefaultButton
                  style={styles.customButtom}
                  handlePress={() => setIsShowing(false)}
                  title={cancelButtonTitle}
                  testID="base-modal-cancel-button"
                />
              )}
              <PrimaryButton
                style={styles.customButtom}
                handlePress={handleConfirm}
                title={confirmationButtonTitle}
                testID="base-modal-confirm-button"
              />
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </SafeAreaView>
    );
  };

  return (
    <ModalContext.Provider value={{ isShowing, showModal, hideModal }}>
      { renderModal() }
      { children }
    </ModalContext.Provider>
  );
};

const styles = StyleSheet.create({
  alertFooter: {
    justifyContent: 'flex-end',
    backgroundColor: defaultColor,
    borderTopWidth: 1,
    borderColor: lineGrey,
  },
  alertBody: {
    backgroundColor: defaultColor,
  },
  alertHeader: {
    backgroundColor: headerGrey,
  },
  customButtom: {
    borderRadius: 8,
  },
});
