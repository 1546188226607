export const filterCurrency = (
  value: string,
  symbol?: string,
  decimals?: number,
  options?: any,
) => {
  const digitsRE = /(\d{3})(?=\d)/g;
  options = options || {};
  const floatValue = parseFloat(value);

  if (options.allowZero === false && floatValue === 0) {
    return '0';
  }

  if (!isFinite(floatValue) || (!floatValue && floatValue !== 0)) {
    return '';
  }

  symbol = symbol != null ? symbol : '$';
  decimals = decimals != null ? decimals : 2;
  const thousandsSeparator =
    options.thousandsSeparator != null ? options.thousandsSeparator : ',';
  const symbolOnLeft =
    options.symbolOnLeft != null ? options.symbolOnLeft : true;
  const spaceBetweenAmountAndSymbol =
    options.spaceBetweenAmountAndSymbol != null
      ? options.spaceBetweenAmountAndSymbol
      : false;
  let stringified = Math.abs(floatValue).toFixed(decimals);
  stringified = options.decimalSeparator
    ? stringified.replace('.', options.decimalSeparator)
    : stringified;
  const _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
  const i = _int.length % 3;
  const head =
    i > 0 ? _int.slice(0, i) + (_int.length > 3 ? thousandsSeparator : '') : '';
  const _float = decimals ? stringified.slice(-1 - decimals) : '';
  symbol = spaceBetweenAmountAndSymbol
    ? symbolOnLeft
      ? symbol + ' '
      : ' ' + symbol
    : symbol;
  symbol = symbolOnLeft
    ? symbol +
      head +
      _int.slice(i).replace(digitsRE, '$1' + thousandsSeparator) +
      _float
    : head +
      _int.slice(i).replace(digitsRE, '$1' + thousandsSeparator) +
      _float +
      symbol;
  const sign = floatValue < 0 ? '-' : '';

  return sign + symbol;
};

export const stringAmountToNumber = (
  amount: string,
  allowNegativeValues = false,
  withCents = false,
): number => {
  // replace all characters which are not digit or dot
  const clearedValue = Number(amount.replace(/[^0-9.]/g, ''));
  if (!clearedValue || amount.length === 0) {
    return 0;
  }

  if (!allowNegativeValues) {
    return withCents ? Math.round(clearedValue * 100) : clearedValue;
  }

  const possibleSign = amount[0];
  const returnedValue = possibleSign === '-' ? clearedValue * -1 : clearedValue;
  return withCents ? Math.round(returnedValue * 100) : returnedValue;
};

export const applyCurrencyFormat = (
  value: string,
  decimals = 2,
  allowZero = true,
  allowNegativeValues = false,
) => {
  if (typeof value === 'undefined') {
    return '';
  }
  return filterCurrency(
    stringAmountToNumber(value, allowNegativeValues) as unknown as string,
    '$',
    decimals,
    { allowZero: allowZero },
  );
};

export const centsToDollars = (amountInCents: number): number => {
  let amount: number;
  if (amountInCents !== null) {
    amount = amountInCents / 100;
  }
  return amount;
};

export const dollarsToCents = (amountInCents: number): number => {
  let amount: number;

  if (amountInCents !== null) {
    amount = amountInCents * 100;
  }
  return amount;
};
