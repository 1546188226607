import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import { h4, defaultGreyColor } from '@styles/app';
import { BackButtonFCInterface } from '@ownTypes/ButtonTypes';

const BackButton: React.FC<BackButtonFCInterface> = ({ handleBackButton }) => {
  return (
    <TouchableOpacity
      style={styles.button}
      onPress={handleBackButton}
      testID="header-back-button"
    >
      <Icon name="chevron-left" size={h4} color={defaultGreyColor} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    marginRight: 10,
    justifyContent: 'center',
  },
});

export default BackButton;
