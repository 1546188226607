import React from 'react';
import { TouchableOpacity, StyleSheet, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import ROUTES from '@navigation/routes';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

const logo = require('@images/logo.png');

const LogoImage: React.FC = (): JSX.Element => {
  const navigation: NativeStackNavigationProp<any> = useNavigation();

  const handleLogoPress = () => {
    const hasLogin = navigation
      .getState()
      .routeNames.includes(ROUTES.LOGIN.name);

    navigation.navigate(hasLogin ? ROUTES.LOGIN.name : ROUTES.RENT_PAY.name);
  };

  return (
    <TouchableOpacity onPress={handleLogoPress} testID="stessa-logo">
      <Image style={styles.logo} source={logo} alt="stessa logo" />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  logo: {
    width: 100,
    height: 50,
    resizeMode: 'contain',
  },
});

export default LogoImage;
