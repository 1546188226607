export enum ALERT_STATUS {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export interface AlertType {
  description: string;
  title?: string;
  status?: ALERT_STATUS;
}

export interface AlertTypes {
  [alertName: string]: AlertType;
}

export const ALERT_TYPES: AlertTypes = {
  PAYMENT_SUCCESS: {
    title: 'Payment',
    description: 'Your payment was transfered with success.',
  },
  PAYMENT_ERROR: {
    title: 'Payment',
    status: ALERT_STATUS.ERROR,
    description: '',
  },
  PAYMENT_PENDING: {
    title: 'Payment',
    description:
      'Your payment was completed but it will take a few minutes to be procesed.',
  },
  ACCOUNT_VERIFIED: {
    title: 'Account',
    description: 'Your account has been verified.',
  },
  ACCOUNT_LOGIN_REQUIRED: {
    title: 'Refresh Connection',
    description: 'One or more of your bank accounts need to be reconnected as your connection is not valid anymore.',
  },
  ACCOUNT_PENDING_EXPIRATION: {
    title: 'Refresh Connection',
    description: 'One or more of your bank accounts need to be reconnected as your connection is about to expire.',
  },
  ACCOUNT_LINKED: {
    title: 'Refresh Connection',
    description: 'Your bank account is now linked.',
  },
  NO_INTERNET_CONNECTION: {
    title: 'No internet connection.',
    description: 'Check your connection to internet.',
    status: ALERT_STATUS.ERROR,
  },
};
