import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import { dangerColor } from '@styles/app';
import { BaseFormErrorFCInterface } from '@ownTypes/ComponentsTypes';

const BaseFormError: React.FC<BaseFormErrorFCInterface> = ({
  children: message,
}): JSX.Element => {
  return (
    <View style={styles.container}>
      <Icon style={styles.errorIcon} name="exclamation-circle" />
      <Text style={styles.errorText} testID="input-error-message">
        {message}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorIcon: {
    color: dangerColor,
    marginRight: 5,
  },
  errorText: {
    color: dangerColor,
  },
});

export default BaseFormError;
