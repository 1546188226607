import React from 'react';
import { View } from 'react-native';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import BoldText from '@components/structure/BoldText';
import Paragraph from '@components/structure/Paragraph';
import { englishOrdinalSuffix } from '@helpers/appHelper';
import { formatDate, getDueDayOffsetText, getMonthName } from '@helpers/dateHelper';
import { centsToDollars, filterCurrency } from '@helpers/currencyHelper';
import { AutopayConfirmation as AutopayConfirmationMsg } from '@lang/AutopayConfirmation';
import { AutopayConfirmationCreateFCInterface } from '@ownTypes/AutopayTypes';

const Create: React.FC<AutopayConfirmationCreateFCInterface> = ({
  userData,
  paymentSubscription,
  fundingAccount,
  nextScheduledMonth,
  styles,
}) => (
  <View testID="autopay-confirmation-create" style={styles?.paymentInfoWrapper}>
    <BoldText testID="subtitle" style={styles?.thankYouText}>
      <Icon name="check" /> {AutopayConfirmationMsg.create.subtitle}
    </BoldText>

    <Paragraph testID="body">
      {AutopayConfirmationMsg.create.body.replace('{{payee}}', userData?.payee)}
    </Paragraph>

    <Paragraph testID="funding-account">
      {AutopayConfirmationMsg.subscription.funding_account}:{' '}
      <BoldText>****{fundingAccount?.mask}</BoldText>
    </Paragraph>

    <Paragraph testID="authorized-recurring_amount">
      {AutopayConfirmationMsg.subscription.authorized_recurring_amount}:{' '}
      <BoldText>
        {filterCurrency(
          centsToDollars(paymentSubscription?.amount_cents).toString(),
        )}
      </BoldText>
    </Paragraph>

    <Paragraph testID="authorized-date">
      {AutopayConfirmationMsg.subscription.authorized_date}:{' '}
      <BoldText>{formatDate(new Date(), 'MM dd, yyyy')}</BoldText>
    </Paragraph>

    <Paragraph testID="withdrawal-date">
      {AutopayConfirmationMsg.subscription.withdrawal_date}:{' '}
      <BoldText>
        {getDueDayOffsetText(
          paymentSubscription.withdrawal_offset,
          englishOrdinalSuffix(paymentSubscription.withdrawal_date),
        )}
      </BoldText>
    </Paragraph>
    <Paragraph testID="withdrawal-date-ext">
      {AutopayConfirmationMsg.subscription.next_scheduled_date
        .replace('{{next_scheduled_date}}', paymentSubscription?.next_scheduled_date)
        .replace('{{month}}', getMonthName(nextScheduledMonth))}
    </Paragraph>
  </View>
);

export default Create;
