import React from 'react';
import { StyleSheet, View } from 'react-native';
import PrimaryButton from '@components/buttons/PrimaryButton';
import { button_lg, defaultMarginSmall } from '@styles/app';
import { AuthorizePaymentButtonFCInterface } from '@ownTypes/MakePaymentTypes';
import { formatDate } from 'helpers/dateHelper';
import BasicInfo from 'components/BasicInfo';

const AuthorizePaymentButton: React.FC<AuthorizePaymentButtonFCInterface> = ({
  disabled,
  paymentInitiated,
  handleAuthorizePayment,
  userData,
}) => {
  return (
    <View style={styles.button}>
      <PrimaryButton
        disabled={disabled}
        loading={paymentInitiated}
        style={button_lg}
        title="Authorize Payment"
        handlePress={handleAuthorizePayment}
        testID="slide-payment-authorize-button"
      />
      <BasicInfo testID="slide-payment-authorize-info">
        By clicking Authorize Payment, I authorize Stessa, on behalf of {userData?.payee},
        to debit the above amount from my bank account, on{' '}
        {formatDate(new Date(), 'MM dd, yyyy')}. Once I authorize this payment,
        I understand there cannot be any changes or corrections. Transactions
        made after 3:00 PM ET or on a weekend or holiday will be processed the
        next business day.
      </BasicInfo>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: defaultMarginSmall,
  },
});

export default AuthorizePaymentButton;
