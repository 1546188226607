import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { List } from 'immutable';
import { AccountType } from '@ownTypes/reducerTypes/AccountTypes';
import { AccountsStateInterface } from '@ownTypes/StateTypes';

const initialState: AccountsStateInterface = {
  plaidToken: '',
  list: [],
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setPlaidToken(
      state: WritableDraft<AccountsStateInterface>,
      action: PayloadAction<string>,
    ) {
      if (action?.payload) {
        state.plaidToken = action.payload;
      }
    },
    fetchAccounts(
      state: WritableDraft<AccountsStateInterface>,
      action: PayloadAction<AccountType[]>,
    ) {
      if (action?.payload) {
        state.list = List(action.payload);
      }
    },
  },
});

export const { setPlaidToken, fetchAccounts } = accountsSlice.actions;

export default accountsSlice.reducer;
