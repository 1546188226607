const FA5Icons =
  require('react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf').default;

const iconFontStyles = `@font-face {
  src: url(${FA5Icons});
  font-family: FontAwesome5_Solid;
}`;

// Create stylesheet
const style = document.createElement('style');
style.appendChild(document.createTextNode(iconFontStyles));

// Inject stylesheet
document.head.appendChild(style);
export {};
