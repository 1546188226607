import { isMobileBrowser } from 'helpers/appHelper';
import { Platform } from 'react-native';

/* ====DEFAULT COLORS==== */
export const defaultButtonTextColor = '#ffffff';
export const defaultButtonBorderColor = '#4472c4';
export const primaryColor = '#4472c4';
export const defaultColor = '#ffffff';
export const secondaryColor = '#a0a6ab';
export const dangerColor = '#dc3545';
export const successColor = '#48C16C';
export const defaultGreyColor = '#767171';
export const lighterGrey = '#f9fafb';
export const lightGrey = '#d3d3d3';
export const darkGrey = '#414141';
export const lineGrey = '#C0C0C0';
export const headerGrey = '#F8F8F8';
export const tooltipBackgroundColor = '#2C3B4C';

/* ====DEFAULT COLORS==== */

/* ====DEFAULT FONT SIZES==== */
export const defaultFontSize = 14;
export const smallFontSize = 12;
export const smallerFontSize = 10;
export const h1 = 40;
export const h2 = 32;
export const h3 = 28;
export const h4 = 24;
export const h5 = 20;
export const h6 = 16;
/* ====DEFAULT FONT SIZES==== */

/* ====DEFAULT BUTTONS==== */
export const button = {
  borderRadius: 4,
  textAlign: 'center',
  textAlignVertical: 'center',
  padding: 10,
  cursor: 'pointer',
  color: defaultColor,
  minWidth: 50,
  minHeight: 42,
  borderWidth: 1,
  borderColor: 'transparent',
  text: {
    color: defaultColor,
    textAlign: 'center',
  },
  loadingColor: defaultColor,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

export const primaryButton = {
  ...button,
  backgroundColor: primaryColor,
};

export const dangerButton = {
  ...button,
  backgroundColor: dangerColor,
};

export const successButton = {
  ...button,
  backgroundColor: successColor,
};

export const defaultButton = {
  ...button,
  color: primaryColor,
  backgroundColor: defaultColor,
  borderColor: primaryColor,
  loadingColor: primaryColor,
  text: {
    ...button.text,
    color: primaryColor,
  },
};

export const defaultLink = {
  ...button,
  color: primaryColor,
  borderWidth: 0,
  loadingColor: primaryColor,
  text: {
    ...button.text,
    color: primaryColor,
  },
};

export const buttonDisabled = {
  ...button,
  backgroundColor: secondaryColor,
  userSelect: 'none',
  pointerEvents: 'none',
  text: {
    ...button.text,
    color: defaultColor,
  },
};

export const button_lg = {
  text: {
    fontSize: h5,
  },
  paddingVerticaly: 8,
  paddingHorizontal: 16,
};
/* ====DEFAULT BUTTONS==== */

/* ====CUSTOM SIZES==== */
export const headerSize = 72;
/* ====CUSTOM SIZES==== */

/* ====LAYOUT==== */
export const col_1 = { width: '8.333333%' };
export const col_2 = { width: '16.666667%' };
export const col_3 = { width: '25%' };
export const col_4 = { width: '33.333333%' };
export const col_5 = { width: '41.666667%' };
export const col_6 = { width: '50%' };
export const col_7 = { width: '58.333333%' };
export const col_8 = { width: '66.666667%' };
export const col_9 = { width: '75%' };
export const col_10 = { width: '83.333333%' };
export const col_11 = { width: '91.666667%' };
export const col_12 = { width: '100%' };
/* ====LAYOUT==== */

/* ====WEIGHT==== */
export const defaultFontWeight = '600';
/* ====WEIGHT==== */

/* ====MARGINS==== */
export const defaultMargin = 40;
export const defaultMarginSmall = 20;
export const marginSubtitle = 20;
export const textMargin = 10;
/* ====MARGINS==== */

/* ====PADDINGS==== */
export const defaultSectionPadding = 10;
/* ====PADDINGS==== */

/* ====TEXT==== */
export const alignLeft = { textAlign: 'left' };
export const alignCenter = { textAlign: 'center' };
export const alignRight = { textAlign: 'right' };
/* ====TEXT==== */

/* ====DEFAULT SCREEN THEME==== */
export const defaultTheme = {
  colors: {
    background: defaultColor,
    text: defaultGreyColor,
  },
};
/* ====DEFAULT SCREEN THEME==== */

/* ====MOBILE LAYOUT==== */
export const shortColMobile = { width: '20%' };
export const longColMobile = { width: '40%' };
/* ====MOBILE LAYOUT==== */

/* ====STYLE CHECKERS==== */
export const linkStyle = {
  color: defaultGreyColor,
  fontSize: isMobileBrowser && 9,
  lineHeight: 24,
};

export const tableItemStyle =
  Platform.OS === 'web'
    ? [col_2, isMobileBrowser ? alignCenter : alignRight]
    : [shortColMobile, { alignItems: 'center' }];

export const shortHeaderStyle = isMobileBrowser ? col_3 : col_2;
export const longHeaderStyle = isMobileBrowser ? col_3 : col_4;
/* ====STYLE CHECKERS==== */

/* ====TOOLTIP STYLES==== */
export const tooltipArrowDimensions = {
  height: 10,
  width: 10,
};

export const tooltipBoxDimensions = {
  width: 200,
};
/* ====TOOLTIP STYLES==== */
