import React from 'react';
import { View, StyleSheet, Platform, Text } from 'react-native';
import HorizontalSeparator from './headerUtils/HorizontalSeparator';
import BackButton from '@components/buttons/BackButton';
import PageTitle from './headerUtils/PageTitle';
import LogoutButton from './headerUtils/LogoutButton';
import LogoImage from './headerUtils/LogoImage';
import {
  defaultColor,
  lightGrey,
  defaultGreyColor,
  smallFontSize,
} from '@styles/app';

import { NavigationTypes } from '@ownTypes/GenericTypes';
const packageJson = require('../../../package.json');

const Header: React.FC<NavigationTypes> = ({
  route,
  navigation,
}): JSX.Element => {
  const handleBackButton = () => {
    navigation.pop();
  };

  const showBackButton = (): boolean => {
    return Platform.OS !== 'web' && navigation.canGoBack();
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {showBackButton() && <BackButton handleBackButton={handleBackButton} />}
        {!showBackButton() && (
          <>
            <LogoImage />
            <HorizontalSeparator />
          </>
        )}
        <PageTitle title={route?.params?.title || route.name} />
        {Platform.OS !== 'web' && process.env.NODE_ENV !== 'production' && (
          <View style={styles.versionHolder}>
            <Text style={styles.version}>{`v ${packageJson.version}`}</Text>
          </View>
        )}
        <LogoutButton />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: defaultColor,
  },
  header: {
    flexDirection: 'row',
    width: '100%',
    borderBottomWidth: 1,
    borderColor: lightGrey,
    padding: 10,
  },
  version: {
    fontSize: smallFontSize,
    color: defaultGreyColor,
    paddingRight: 10,
    paddingTop: 2,
  },
  versionHolder: {
    justifyContent: 'center',
  },
});

export default Header;
