import React from 'react';
import { View } from 'react-native';
import { TableFCInterface } from '@ownTypes/TableTypes';
import TableHeader from './TableHeader';
import TableItem from './TableItem';
import TableRow from './TableRow';

const Table: React.FC<TableFCInterface> = ({
  style,
  children: childComponent,
}): JSX.Element => {
  return <View style={[style]}>{childComponent}</View>;
};

export default {
  Table,
  TableHeader,
  TableRow,
  TableItem,
};
