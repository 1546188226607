import React from 'react';
import { View, StyleSheet } from 'react-native';
import PrimaryButton from '@components/buttons/PrimaryButton';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import { h6 } from '@styles/app';
import { BankActionsFCInterface } from '@ownTypes/MakePaymentTypes';
import { ACCOUNT_STATUS } from '@ownTypes/AccountStatusTypes';

const BankActions: React.FC<BankActionsFCInterface> = ({
  status,
  accountId,
  handleDeleteAccount,
  handleVerifyAccount,
  handleRefreshAccount,
}) => {
  return (
    <View style={styles.container}>
      {status === ACCOUNT_STATUS.PENDING_VERIFICATION && (
        <PrimaryButton
          title={<Icon name="sync" size={h6} />}
          handlePress={() => handleVerifyAccount(accountId)}
          testID="account-verify"
          style={styles.button}
        />
      )}
      {(status === ACCOUNT_STATUS.LOGIN_REQUIRED ||
        status === ACCOUNT_STATUS.PENDING_EXPIRATION) && (
        <PrimaryButton
          title={<Icon name="redo-alt" size={h6} />}
          handlePress={() => handleRefreshAccount(accountId)}
          testID="account-verify"
          style={styles.button}
        />
      )}
      <PrimaryButton
        title={<Icon name="trash" size={h6} />}
        handlePress={() => handleDeleteAccount(accountId)}
        testID="account-delete"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  button: {
    marginRight: 10,
  },
});

export default BankActions;
