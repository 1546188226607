import { LinkAcc } from '@ownTypes/PlaidTypes';

export const LinkAccount: LinkAcc = (
  plaidToken,
  onSuccessHandler,
  onExitHandler,
) => {
  const Plaid = global.Plaid;

  const linkHandler = Plaid.create({
    token: plaidToken,
    onSuccess: onSuccessHandler,
    onExit: onExitHandler,
  });

  linkHandler.open();
};
