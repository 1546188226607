import React from 'react';
import { View, StyleSheet } from 'react-native';
import { lighterGrey, defaultFontWeight } from '@styles/app';
import { TableFCInterface } from '@ownTypes/TableTypes';

const TableHeader: React.FC<TableFCInterface> = ({
  style,
  children: childComponent,
  testID,
}) => {
  const items = React.Children.map(childComponent, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isTableHeader: true } as any);
    }
    return child;
  });

  return (
    <View style={[style, styles.header]} testID={testID}>
      {items}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    backgroundColor: lighterGrey,
    padding: 10,
    borderBottomWidth: 1,
    borderColor: '#cfd3da',
  },
  headerItem: {
    fontWeight: defaultFontWeight,
  },
});

export default TableHeader;
