export const formatDate = (date: Date, format: string) => {
  const monthStrings = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const year = date.getFullYear();

  let formattedDate: string;
  switch (format) {
    case 'month yyyy':
      formattedDate = monthStrings[date.getMonth()] + ` ${String(year)}`;
      break;
    case 'month dd':
      formattedDate = monthStrings[date.getMonth()] + ` ${day}`;
      break;
    case 'MM dd, yyyy':
      formattedDate = `${monthStrings[date.getMonth()]} ${day}, ${year}`;
      break;
    case 'mm/yy':
      formattedDate = month + '/' + String(year).slice(2);
      break;
    case 'mm/dd/yy':
      formattedDate = month + '/' + day + '/' + String(year).slice(2);
      break;
    case 'mm/dd/yyyy':
      formattedDate = month + '/' + day + '/' + year;
      break;
    case 'mm-dd-yyyy':
      formattedDate = month + '-' + day + '-' + year;
      break;
    case 'yyyy-mm-dd':
      formattedDate = year + '-' + month + '-' + day;
      break;
    case 'm/d/yyyy':
      formattedDate = Number(month) + '/' + Number(day) + '/' + year;
      break;
    default:
      formattedDate = month + '/' + day + '/' + year;
  }

  return formattedDate;
};

export const getDueDayOffsetText = (
  currentOffset: string | number,
  withdrawalDay: string,
) => {
  const offset = typeof currentOffset === 'number' ? currentOffset : Number(currentOffset);
  const day = Math.abs(offset) !== 1 ? 'days' : 'day';

  if (offset < 0) {
    return `${Math.abs(offset)} ${day} before due date`;
  }

  if (offset > 0) {
    return `${currentOffset} ${day} after due date`;
  }

  return `${withdrawalDay} of the month (due date)`;
};

export const getISOString = (dateStr: string): string | null => {
  const date = new Date(dateStr);
  if (isNaN(date.getTime())) {
    return null;
  }
  const computedDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000,
  );
  if (isNaN(computedDate.getTime())) {
    return null;
  }
  return computedDate.toISOString();
};

export const getCurrentISOString = (): string => {
  const date = new Date();
  date.setMilliseconds(0);
  return date.toISOString();
};

export const getMonthName = (date: string | Date): string => {
  return new Date(date).toLocaleString('default', { month: 'long' });
};
