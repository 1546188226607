import { useDispatch } from 'react-redux';
import { SUBSCRIPTION_ACTION_TYPES } from 'store/sagas/actions/SubscriptionActions';
import { DispatchType } from 'types/StoreType';

const useCTACancelAutopay = () => {
  const dispatch: DispatchType = useDispatch();

  return (id: number, callback?: () => void) => {
    if (!id) {
      return false;
    }

    dispatch({
      type: SUBSCRIPTION_ACTION_TYPES.UPDATE_SUBSCRIPTION,
      data: {
        id,
        payload: {
          autopay_enabled: false,
        },
      },
      ...(typeof callback === 'function' ? { callback } : null),
    });
  };
};

export default useCTACancelAutopay;
