import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import PageSubtitleSecondary from '@components/PageSubtitleSecondary';
import BasicSwitch from '@components/inputs/BasicSwitch';
import { defaultGreyColor, defaultMargin } from '@styles/app';

const SetAsRecurringWrapper: React.FC<{
  isRecurring: boolean;
  handleRecurringPayment: (args?: boolean) => void;
  testID?: string;
}> = ({ isRecurring, handleRecurringPayment, testID = '' }) => {
  return (
    <View style={styles.container} testID={testID}>
      <PageSubtitleSecondary>
        <Text>Set as Autopay</Text>
      </PageSubtitleSecondary>
      <BasicSwitch checked={isRecurring} onToggle={handleRecurringPayment} />
      <Text testID="autopay-info-text" style={styles.reocurringInfo}>
        When turned on, payment will occur every month based on the selected
        withdrawal day
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: defaultMargin,
  },
  reocurringInfo: {
    fontStyle: 'italic',
    color: defaultGreyColor,
    marginTop: 10,
  },
});

export default SetAsRecurringWrapper;
