// API AUTH ENDPOINTS
const APP_API_BASE_URL = process.env.APP_API_BASE_URL;
const APP_BASE_URL = process.env.APP_BASE_URL;
export const APP_LOGIN_URL = APP_BASE_URL + '/login';
export const API_AUTH_URL = APP_API_BASE_URL + '/auth';
/* ====API AUTH ENDPOINTS==== */

/* ====API LEDGER ENDPOINTS==== */
export const API_LEDGER = APP_API_BASE_URL + '/ledger';
export const API_PAYMENTS = APP_API_BASE_URL + '/payments';
/* ====API LEDGER ENDPOINTS==== */

/* ====API USERS ENDPOINTS==== */
export const API_USERS_ME = APP_API_BASE_URL + '/users/me';
/* ====API USERS ENDPOINTS==== */

/* ====API PLAID ENDPOINTS==== */
export const API_PLAID_LINK_TOKEN = APP_API_BASE_URL + '/plaid/link_token';
export const API_PLAID_LINK_ACCOUNTS =
  APP_API_BASE_URL + '/plaid/link_accounts';
/* ====API PLAID ENDPOINTS==== */

/* ====API ACCOUNTS ENDPOINTS==== */
export const API_ACCOUNTS = APP_API_BASE_URL + '/accounts';
/* ====API ACCOUNTS ENDPOINTS==== */

/* ====API ACCOUNTS ENDPOINTS==== */
export const API_SUBSCRIPTION = APP_API_BASE_URL + '/payment_subscriptions';
export const API_SUBSCRIPTION_GET = API_SUBSCRIPTION + '/active';
export const API_SUBSCRIPTION_WITHDRAWAL_DATE_OPTIONS =
  API_SUBSCRIPTION + '/withdrawal_date_options';
/* ====API ACCOUNTS ENDPOINTS==== */
