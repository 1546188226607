import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { defaultGreyColor, defaultFontWeight } from '@styles/app';
import { TableItemFCInterface } from '@ownTypes/TableTypes';

const TableItem: React.FC<TableItemFCInterface> = ({
  style,
  children: childComponent,
  isTableHeader,
  testID,
}): JSX.Element => {
  return (
    <View style={style}>
      <Text
        ellipsizeMode="tail"
        style={[styles.text, isTableHeader && styles.tableHeader]}
        testID={testID}
      >
        {childComponent}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    color: defaultGreyColor,
  },
  tableHeader: {
    fontWeight: defaultFontWeight,
  },
});

export default TableItem;
