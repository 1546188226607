export enum TRANSACTION_TYPES {
  NOOP = 'NOOP',
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
}

type NOOP<T> = {
  type: TRANSACTION_TYPES.NOOP;
  data: T;
};

type CREATED<T> = {
  type: TRANSACTION_TYPES.CREATED;
  data: T;
};

type UPDATED<T> = {
  type: TRANSACTION_TYPES.UPDATED;
  data: T;
};

export type TransactionType<T> = NOOP<T> | CREATED<T> | UPDATED<T>;
