import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Radio } from 'native-base';
import BankLogo from './BankLogo';
import BankData from './BankData';
import BankActions from './BankActions';
import { AccountRowFCInterface } from '@ownTypes/MakePaymentTypes';
import { ACCOUNT_STATUS } from '@ownTypes/AccountStatusTypes';

const AccountRow: React.FC<AccountRowFCInterface> = ({
  accountData,
  handleDeleteAccount,
  handleVerifyAccount,
  handleRefreshAccount,
}) => {
  const isDisabledAccount =
    accountData.verification_status === ACCOUNT_STATUS.PENDING_VERIFICATION ||
    accountData.verification_status === ACCOUNT_STATUS.LOGIN_REQUIRED;

  return (
    <View style={styles.container} testID="accounts-items">
      <Radio
        value={accountData.account_id}
        isDisabled={isDisabledAccount}
        testID="account-radio"
        accessibilityLabel="account"
      />
      <BankLogo logo={accountData?.logo} />
      <BankData
        data={accountData}
        handleVerifyAccount={handleVerifyAccount}
        handleRefreshAccount={handleRefreshAccount}
      />
      <BankActions
        accountId={accountData.account_id}
        status={accountData.verification_status}
        handleDeleteAccount={handleDeleteAccount}
        handleVerifyAccount={handleVerifyAccount}
        handleRefreshAccount={handleRefreshAccount}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 10,
  },
});

export default AccountRow;
