import React, { useRef } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { NativeBaseProvider, AlertDialog } from 'native-base';
import Portal from '@burstware/react-native-portal';
import DefaultButton from '@components/buttons/DefaultButton';
import PrimaryButton from '@components/buttons/PrimaryButton';
import SuccessButton from '@components/buttons/SuccessButton';
import { BaseAlertFCInterface } from '@ownTypes/ComponentsTypes';
import { defaultColor, headerGrey, lineGrey } from 'assets/styles/app';

const BaseAlert: React.FC<BaseAlertFCInterface> = ({
  title = 'Tenant Portal Info',
  children: content,
  confirmButtonType,
  confirmButtonName = 'Confirm',
  cancelButtonName,
  handleConfirm,
  handleClose,
  showAlert = false,
  testID,
}): Portal => {
  const alertRef = useRef(null);
  const handleCloseAction = () => {
    if (typeof handleClose === 'function') {
      handleClose();
    }
  };

  const setConfirmButtonType = () => {
    let Component: React.FC<any> = PrimaryButton;
    switch (confirmButtonType) {
      case 'save':
        Component = SuccessButton;
        break;
    }

    return (
      <Component
        style={styles.customButtom}
        handlePress={handleConfrimAction}
        title={confirmButtonName}
        testID="modal-confirm-button"
      />
    );
  };

  const handleConfrimAction = () => {
    if (typeof handleConfirm === 'function') {
      handleConfirm();
    }
  };
  // We use again the NativeBaseProvider because we have problems from React Navigation which is compressing the content to 0px
  // Also I did not found any solution to make the overlay fixed
  return (
    <Portal>
      {showAlert && (
        <SafeAreaView style={styles.alert} nativeID="alertModal">
          <NativeBaseProvider>
            <AlertDialog
              defaultIsOpen={true}
              leastDestructiveRef={alertRef}
              onClose={handleCloseAction}
            >
              <AlertDialog.Content testID={testID}>
                <AlertDialog.CloseButton onPress={handleCloseAction} />
                <AlertDialog.Header
                  style={styles.alertHeader}
                  testID="modal-title"
                >
                  {title}
                </AlertDialog.Header>
                <AlertDialog.Body style={styles.alertBody} testID="modal-body">
                  {content}
                </AlertDialog.Body>
                <AlertDialog.Footer style={styles.alertFooter}>
                  <DefaultButton
                    style={styles.customButtom}
                    handlePress={handleCloseAction}
                    title={cancelButtonName || 'Cancel'}
                    testID="modal-cancel-button"
                  />
                  {setConfirmButtonType()}
                </AlertDialog.Footer>
              </AlertDialog.Content>
            </AlertDialog>
          </NativeBaseProvider>
        </SafeAreaView>
      )}
    </Portal>
  );
};

const styles = StyleSheet.create({
  alert: {
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
  },
  alertFooter: {
    justifyContent: 'space-between',
    backgroundColor: defaultColor,
    borderTopWidth: 1,
    borderColor: lineGrey,
  },
  alertBody: {
    backgroundColor: defaultColor,
  },
  alertHeader: {
    backgroundColor: headerGrey,
  },
  customButtom: {
    borderRadius: 8,
  },
});

export default BaseAlert;
