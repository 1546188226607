import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CONNECTION_TYPES, PASSWORDLESS_METHOD } from '@config/auth0';
import { useDispatch } from 'react-redux';
import ROUTES from '@navigation/routes';
import { APP_LOGIN_URL } from '@store/config/apiUrls';
import { setUserToken } from '@reducers/UserReducer';
import { auth0Messages } from '@ownTypes/Auth0ErrorTypes';
import { NavigationTypes } from '@ownTypes/GenericTypes';

interface LoginTypes extends NavigationTypes {
  storedToken: string;
}

const Login: React.FC<LoginTypes> = ({ navigation, route, storedToken }) => {
  const { loginWithRedirect, handleRedirectCallback, getAccessTokenSilently } =
    useAuth0();
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      const navigateToRentPay = () => {
        navigation.navigate(ROUTES.RENT_PAY.name);
      };

      const redirectToLogin = () => navigation.navigate(ROUTES.LOGIN.name);

      if (process.env.AUTOMATION_MODE === 'true') {
        dispatch(setUserToken('testToken'));
        return navigateToRentPay();
      }

      if (route.params?.error && route.params?.error_description) {
        // Error case
        if (
          route.params?.error_description ===
          auth0Messages.wrongEmailOrVerification
        ) {
          return redirectToLogin();
        } else {
          return navigation.navigate(ROUTES.ERROR_PAGE.name, {
            error: route.params?.error,
            errorDescription: route.params?.error_description,
          });
        }
      }
      if (!storedToken) {
        if (route.params?.state && route.params?.code) {
          try {
            await handleRedirectCallback();
            const token = await getAccessTokenSilently();

            if (token) {
              dispatch(setUserToken(token));
              return navigateToRentPay();
            }
          } catch (err) {
            if (
              err.message === auth0Messages.failedToVerifyCode ||
              err.message === auth0Messages.userAlreadyInSession
            ) {
              // In the situation when you generate 2 magic links, enter on the first one and then go to the second one,
              // auth0 is giving the error 'Failed to verify code' and the library can't retrieve the token, but if you try to
              // retrieve the token again, it will be returned, so I'm making another call to login and user is logged in
              return redirectToLogin();
            }
            return navigation.navigate(ROUTES.ERROR_PAGE.name);
          }
        } else {
          let connectionType = CONNECTION_TYPES.slice(-1)[0];
          let passwordlessMethod = PASSWORDLESS_METHOD.slice(-1)[0];

          if (
            route.params?.connection_type &&
            CONNECTION_TYPES.includes(route.params.connection_type)
          ) {
            connectionType = route.params.connection_type;
          }

          if (
            route.params?.passwordless_method &&
            PASSWORDLESS_METHOD.includes(route.params.passwordless_method)
          ) {
            passwordlessMethod = route.params.passwordless_method;
          }

          return await loginWithRedirect({
            connection_type: connectionType,
            passwordless_method: passwordlessMethod,
            redirectUri: window?.location?.origin
              ? `${window.location.origin}/login`
              : APP_LOGIN_URL,
          });
        }
      }
    })();
  }, [
    route.params,
    dispatch,
    getAccessTokenSilently,
    handleRedirectCallback,
    loginWithRedirect,
    navigation,
    storedToken,
  ]);
  return null;
};

export default Login;
