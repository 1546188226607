import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { SendEvent, IdentifyEvent } from '@ownTypes/SegmentTypes';

const writeKey = process.env.SEGMENT_WEB_KEY;
let analytics: Analytics;

const loadAnalytics = async (): Promise<Analytics> => {
  if (analytics) {
    return analytics;
  }
  const [response] = await AnalyticsBrowser.load({ writeKey });
  analytics = response;
  return analytics;
};

export const sendEvent: SendEvent = async (name, props) => {
  const usedAnalytics = await loadAnalytics();
  usedAnalytics?.track(name, props);
};

export const identify: IdentifyEvent = async (userId, props) => {
  const usedAnalytics = await loadAnalytics();
  usedAnalytics?.identify(userId, props);
};
