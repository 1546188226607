import React, { useState, useRef } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import AccountRow from './AccountRow';
import { Radio } from 'native-base';
import { ACCOUNTS_ACTION_TYPES } from '@sagas/actions/AccountsActions';
import { setMakePaymentOverlay } from '@reducers/AppReducer';
import NoAccountsInfo from './NoAccountInfo';
import { createAccount } from '@services/plaid';
import { ALERT_STATUS, ALERT_TYPES } from '@ownTypes/AlertTypes';
import { StoreInterface, DispatchType } from '@ownTypes/StoreType';
import { AccountsWrapperFCInterface } from '@ownTypes/MakePaymentTypes';
import CustomConfirmationModal from 'components/CustomConfirmationModal';
import { ALERT_TEXT } from 'language/AlertText';
import { SubscriptionStatus } from 'types/reducerTypes/SubscriptionTypes';
import useSelectableAccounts from '@hooks/useSelectableAccounts';
import { setSubscription } from 'store/reducers/UserReducer';
import { useModalContext } from '@components/BaseModal';
import PlaidUserInstructionsModal from '@views/MakePayment/Accounts/Modals/PlaidUserInstructionsModal';
import { AccountType } from 'types/reducerTypes/AccountTypes';
import { ACCOUNT_STATUS } from 'types/AccountStatusTypes';

const AccountsWrapper: React.FC<AccountsWrapperFCInterface> = ({
  setAccount,
  setAlert,
  selectedAccountId = '',
  deleteActiveAccountCallback,
}) => {
  const dispatch: DispatchType = useDispatch();
  const accounts = useSelector((state: StoreInterface) => state.accounts.list);
  const paymentSubscription = useSelector(
    (state: StoreInterface) => state.user.paymentSubscription,
  );
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [accountId, setAccountId] = useState<string>();
  const [deleteAccountId, setDeleteAccountId] = useState<string>();
  const [deleteMessage, setDeleteMessage] = useState('');
  const mounted = useRef(false);
  const onlySelectableAccounts = useSelectableAccounts(accounts);
  const { showModal, hideModal } = useModalContext();

  React.useEffect(() => {
    // For preventing disabling overlay when component is mounted
    if (mounted.current) {
      dispatch(setMakePaymentOverlay(false));
    }

    if (!!accounts?.size && !mounted.current && typeof setAlert === 'function') {
      if (selectedAccountId) {
        const selectedOption = accounts.find(({ account_id }) => account_id === selectedAccountId);
        selectedOption && setAccount(selectedOption);
      }

      const loginRequired = accounts.find(
        (item: AccountType) =>
          item.verification_status === ACCOUNT_STATUS.LOGIN_REQUIRED,
      );
      if (loginRequired) {
        setAlert({
          status: ALERT_STATUS.WARNING,
          title: ALERT_TYPES.ACCOUNT_LOGIN_REQUIRED.title,
          description: ALERT_TYPES.ACCOUNT_LOGIN_REQUIRED.description,
        });
      } else {
        const pendingExpiration = accounts.find(
          (item: AccountType) =>
            item.verification_status === ACCOUNT_STATUS.PENDING_EXPIRATION,
        );
        if (pendingExpiration) {
          setAlert({
            status: ALERT_STATUS.WARNING,
            title: ALERT_TYPES.ACCOUNT_PENDING_EXPIRATION.title,
            description: ALERT_TYPES.ACCOUNT_PENDING_EXPIRATION.description,
          });
        }
      }
    }

    mounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accounts, setAlert]);

  React.useEffect(() => {
    if (selectedAccountId && !accountId) {
      setAccountId(selectedAccountId);
    } else if (!selectedAccountId && !accountId && typeof setAccount === 'function') {
      if (onlySelectableAccounts.size > 0) {
        const selectedOption = accountId
          ? onlySelectableAccounts.find(
              ({ account_id }) => account_id === accountId,
            )
          : onlySelectableAccounts.first();

        setAccount(selectedOption);
        setAccountId(selectedOption?.account_id);
      } else {
        setAccount(null);
        setAccountId(null);
      }
    }
  }, [accountId, onlySelectableAccounts, selectedAccountId, setAccount]);

  const handleSetAccountId = (newAccountId: string): void => {
    setAccountId(newAccountId);
    const selectedOption = accounts.find(
      ({ account_id }) => account_id === newAccountId,
    );
    setAccount(selectedOption);
  };

  const handleDeleteAccount = (accountID: string): void => {
    if (
      paymentSubscription?.data?.status === SubscriptionStatus.ACTIVE &&
      paymentSubscription?.data?.account_id === accountID
    ) {
      setDeleteMessage(ALERT_TEXT.DELETE_ACCOUNT.message_autopay);
    } else {
      setDeleteMessage(ALERT_TEXT.DELETE_ACCOUNT.message_default);
    }

    setDeleteAccountId(accountID);
    setShowDeleteAlert(true);
  };

  const handleClose = (): void => {
    setShowDeleteAlert(false);
    setDeleteAccountId(null);
  };

  const handleConfirmDelete = (): void => {
    dispatch(setMakePaymentOverlay(true));
    setShowDeleteAlert(false);
    dispatch({
      type: ACCOUNTS_ACTION_TYPES.DELETE_ACCOUNT,
      data: { account_id: deleteAccountId },
      successCallback: () => {
        dispatch({
          type: ACCOUNTS_ACTION_TYPES.FETCH_ACCOUNTS,
        });

        if (
          paymentSubscription?.data?.status === SubscriptionStatus.ACTIVE &&
          paymentSubscription?.data?.account_id === deleteAccountId
        ) {
          dispatch(setSubscription(null));
          if (typeof deleteActiveAccountCallback === 'function') {
            deleteActiveAccountCallback();
          }
        }
        setDeleteAccountId(null);
      },
      errorCallback: () => {
        dispatch(setMakePaymentOverlay(false));
        setDeleteAccountId(null);
      },
    });
  };

  const handleVerifyAccount = (accountID: string): void => {
    showModal({
      title: 'Instructions for linking with Plaid',
      testID: 'instructions-verify-account-modal',
      body: <PlaidUserInstructionsModal />,
      size: 'lg',
      confirmationButtonTitle: 'Confirm & proceed',
      handleConfirm: () => {
        hideModal();
        dispatch(setMakePaymentOverlay(true));

        createAccount(accountID)
          .then(() => {
            if (typeof setAlert === 'function') {
              setAlert({
                status: ALERT_STATUS.SUCCESS,
                title: ALERT_TYPES.ACCOUNT_VERIFIED.title,
                description: ALERT_TYPES.ACCOUNT_VERIFIED.description,
              });
            }
          })
          .catch(() => dispatch(setMakePaymentOverlay(false)));
      },
    });
  };

  const handleRefreshAccount = (accountID: string): void => {
    dispatch(setMakePaymentOverlay(true));
    const callback = (error?) => {
      dispatch(setMakePaymentOverlay(false));
      if (!error && typeof setAlert === 'function') {
        setAlert({
          status: ALERT_STATUS.SUCCESS,
          title: ALERT_TYPES.ACCOUNT_LINKED.title,
          description: ALERT_TYPES.ACCOUNT_LINKED.description,
        });
      }
    };
    createAccount(accountID, null, callback);
  };

  return (
    <View style={styles.container}>
      {accounts.size > 0 ? (
        <Radio.Group
          name="payments"
          defaultValue={accounts.first().account_id}
          value={accountId}
          onChange={handleSetAccountId}
          accessibilityLabel="Select an account"
        >
          {accounts.map(account => (
            <AccountRow
              key={account.account_id}
              accountData={account}
              handleDeleteAccount={handleDeleteAccount}
              handleVerifyAccount={handleVerifyAccount}
              handleRefreshAccount={handleRefreshAccount}
            />
          ))}
        </Radio.Group>
      ) : (
        <NoAccountsInfo />
      )}
      <CustomConfirmationModal
        handleClose={handleClose}
        handleConfirm={handleConfirmDelete}
        showAlert={showDeleteAlert}
        title={ALERT_TEXT.DELETE_ACCOUNT.title}
        confirmationButtonText={
          ALERT_TEXT.DELETE_ACCOUNT.confirmationButtonText
        }
        message={deleteMessage}
        confirmation={ALERT_TEXT.DELETE_ACCOUNT.confirmation}
        icon={'trash'}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
  },
});

export default AccountsWrapper;
