const defaultInfo1 =
  'I authorize Stessa, on behalf of {{payee}}, to #action# my recurring ACH/electronic debits in the amount of {{amount}} from the above identified financial account on the date listed above ';
const defaultInfo2 =
  'You may modify auto pay settings to change the amount or date of payments or to stop recurring payments. Changes must be submitted at least five (5) business days before your next scheduled transfer. Changes made less than five (5) business days before your next scheduled transfer will go into effect the following month.';
const defaultInfo3 = 'It is recommended that you print a copy of this authorization and maintain it for your records.';

export const Autopay = {
  create: {
    title: 'Set Up Autopay',
    info1: defaultInfo1.replace('#action#', 'initiate'),
    info1_bold: 'and every 30 days thereafter',
    info2: defaultInfo2,
    info3: defaultInfo3,
  },
  update: {
    title: 'Edit Autopay',
    info1: defaultInfo1.replace('#action#', 'update'),
    info1_bold: 'and every 30 days thereafter',
    info2: defaultInfo2,
    info3: defaultInfo3,
  },
};
