import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, SafeAreaView, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import SideModal from '@views/SideModal';
import RentPayWrapper from './index.wrapper';
import load from 'load-script';
import Footer from '@components/structure/Footer';
import { DispatchType, StoreInterface } from '@ownTypes/StoreType';
import { useIntercom } from 'react-use-intercom';
import PaymentConfirmation from 'views/MakePayment/PaymentConfirmation';
import ROUTES from 'navigation/routes';
import { setSideModalToggle } from 'store/reducers/AppReducer';
import { setLastPaymentResponse } from 'store/reducers/PaymentsReducer';
import { SetSideModalFunction } from 'types/RentPayTypes';
import MakePayment from '@views/MakePayment/index';
import Confirmation from '@views/AutoPay/Confirmation';
import { TRANSACTION_TYPES } from '@ownTypes/TransactionTypes';
import { AutopayConfirmation } from '@lang/AutopayConfirmation';

const RentPay: React.FC = () => {
  const dispatch: DispatchType = useDispatch();
  const { update } = useIntercom();
  const userData = useSelector((state: StoreInterface) => state.user.data);
  const paymentSubscription = useSelector(
    (state: StoreInterface) => state.user.paymentSubscription,
  );
  const lastPaymentResponse = useSelector(
    (state: StoreInterface) => state.payments.lastPaymentResponse,
  );
  const isModalShown = useSelector(
    (state: StoreInterface) => state.app.sideModalToggle,
  );

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [sideModalData, setSideModalData] = useState(null);
  const fadeAnim = useRef(new Animated.Value(1)).current;

  const fade = useCallback(
    (toValue = 1, finished?: () => void) => {
      return Animated.timing(fadeAnim, {
        toValue: toValue,
        duration: 300,
        useNativeDriver: false,
      }).start(() => {
        if (finished && typeof finished === 'function') {
          finished();
        }
      });
    },
    [fadeAnim],
  );

  const transform = [
    {
      translateY: fadeAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [20, 0],
      }),
    },
  ];

  const handleMakeAnotherPaymentPress = useCallback(() => {
    fade(0, () => {
      dispatch(setLastPaymentResponse(null));
      setSideModalData({
        component: <MakePayment />,
        title: ROUTES.MAKE_PAYMENT.name,
      });
      fade();
    });
  }, [dispatch, fade]);

  const handleSetSideModalComponent: SetSideModalFunction = ({
    component: Component,
    title = '',
  }) => {
    setSideModalData({
      component: <Component />,
      title,
    });
    dispatch(setSideModalToggle(true));
  };

  useEffect(() => {
    load('https://cdn.plaid.com/link/v2/stable/link-initialize.js', () => {
      setIsScriptLoaded(true);
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (isModalShown) {
      dispatch(setLastPaymentResponse(null));
    }
  }, [isModalShown, dispatch]);

  React.useEffect(() => {
    if (lastPaymentResponse) {
      fade(0, () => {
        setSideModalData({
          component: (
            <PaymentConfirmation
              handleMakeAnotherPayment={handleMakeAnotherPaymentPress}
              handleClose={() => {
                dispatch(setSideModalToggle(false));
              }}
            />
          ),
          title: ROUTES.PAYMENT_CONFIRMATION.name,
        });
        fade();
      });
    }
  }, [lastPaymentResponse, fade, handleMakeAnotherPaymentPress, dispatch]);

  React.useEffect(() => {
    if (
      paymentSubscription &&
      paymentSubscription.type !== TRANSACTION_TYPES.NOOP
    ) {
      const confirmationTitle =
        paymentSubscription.type === TRANSACTION_TYPES.CREATED
          ? AutopayConfirmation.create.title
          : AutopayConfirmation.update.title;

      fade(0, () => {
        setSideModalData({
          component: (
            <Confirmation
              handleClose={() => {
                dispatch(setSideModalToggle(false));
              }}
            />
          ),
          title: confirmationTitle,
        });
        fade();
      });
    }
  }, [paymentSubscription, fade, dispatch]);

  useEffect(() => {
    if (userData.name && userData.id) {
      update({
        name: userData.name,
        email: userData.email,
        userId: userData.uid,
        userHash: userData.intercom_hash_uid.web,
      });
    }
  }, [update, userData]);
  return (
    <>
      <SafeAreaView style={styles.container} testID="rent-pay-view">
        <RentPayWrapper
          isScriptLoaded={isScriptLoaded}
          setSideModalComponent={handleSetSideModalComponent}
        />
        <SideModal title={sideModalData?.title}>
          <Animated.View
            style={[
              {
                transform,
                opacity: fadeAnim,
              },
            ]}
          >
            {sideModalData?.component ? sideModalData.component : null}
          </Animated.View>
        </SideModal>
      </SafeAreaView>
      <Footer />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 1,
  },
});

export default RentPay;
