import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { List } from 'immutable';
import { PaymentStateInterface } from '@ownTypes/StateTypes';
import {
  TransactionResponseType,
  TransactionType,
} from '@ownTypes/reducerTypes/PaymentTypes';
import { getLastBalance } from 'helpers/appHelper';

const initialState: PaymentStateInterface = {
  payments: [],
  paymentError: '',
  lastPayment: null,
  lastPaymentResponse: null,
  editAutopay: false,
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    fetchPayments(
      state: WritableDraft<PaymentStateInterface>,
      action: PayloadAction<{ data: { ledger: TransactionType[] } }>,
    ) {
      if (action?.payload?.data?.ledger) {
        state.payments = List(action.payload.data.ledger);

        if (state.payments?.size > 0) {
          state.lastPayment = getLastBalance(state.payments);
        }
      }
    },
    setPaymentError(
      state: WritableDraft<PaymentStateInterface>,
      action: PayloadAction<string>,
    ) {
      state.paymentError = action.payload;
    },
    clearPaymentError(state: WritableDraft<PaymentStateInterface>) {
      state.paymentError = '';
    },
    setLastPaymentResponse(
      state: WritableDraft<PaymentStateInterface>,
      action: PayloadAction<TransactionResponseType>,
    ) {
      state.lastPaymentResponse = action.payload;
    },
  },
});

export const {
  fetchPayments,
  setPaymentError,
  clearPaymentError,
  setLastPaymentResponse,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
