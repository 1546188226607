import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { defaultGreyColor, h6 } from '@styles/app';
import { CustomerInfoFCInterface } from '@ownTypes/RentPayTypes';

const CustomerInfo: React.FC<CustomerInfoFCInterface> = ({
  address,
  unit,
}): JSX.Element => {
  return (
    <Text style={styles.text} testID="rent-pay-property-address">
      {unit ? `${address}, Unit ${unit}` : `${address}`}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    color: defaultGreyColor,
    fontSize: h6,
  },
});

export default CustomerInfo;
