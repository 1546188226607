import React from 'react';
import { View, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import { h5, defaultGreyColor } from '@styles/app';
import { Image } from 'native-base';
import { BankLogoFCInterface } from '@ownTypes/MakePaymentTypes';

const BankLogo: React.FC<BankLogoFCInterface> = ({ logo }) => {
  return (
    <View>
      {logo ? (
        <Image
          style={styles.logo}
          source={{ uri: `data:image/png;base64, ${logo}` }}
          alt="Bank logo"
          testID="account-bank-logo"
        />
      ) : (
        <View style={[styles.defaultLogo]} testID="account-bank-default-logo">
          <Icon name="university" size={h5} color={defaultGreyColor} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  logo: {
    width: 40,
    height: 40,
    marginHorizontal: 10,
  },
  defaultLogo: {
    padding: 10,
    marginHorizontal: 10,
    backgroundColor: '#f8f8f8',
    borderRadius: 10,
  },
});

export default BankLogo;
