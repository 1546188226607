import { takeLatest, ForkEffect } from 'redux-saga/effects';
import {
  handlePlaidToken,
  handleLinkAccounts,
} from '@sagas/handlers/PlaidHandler';
import { PLAID_ACTION_TYPES } from '@sagas/actions/PlaidActions';

export default function* plaidSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(PLAID_ACTION_TYPES.PLAID_TOKEN, handlePlaidToken);
  yield takeLatest(PLAID_ACTION_TYPES.LINK_ACCOUNTS, handleLinkAccounts);
}
