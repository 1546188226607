export const ALERT_TEXT = {
  DELETE_ACCOUNT: {
    title: 'Confirm delete account',
    confirmationButtonText: 'Delete',
    message_default:
      'Deleting this account will remove it as an option to pay rent. You can always add it back later. You will need a bank account to use Autopay.',
    message_autopay: 'Deleting this account will cancel autopay.',
    confirmation: 'Are you sure you want to delete?',
  },
  CANCEL_AUTOPAY: {
    title: 'Confirm Autopay Cancellation',
    confirmationButtonText: 'Confirm Cancellation',
    message:
      'Cancelling auto pay will stop all payments scheduled more than five (5) days in the future.',
    confirmation: 'Are you sure you wish to cancel future payments?',
  },
  RECOMMENDED_APP_UPDATE: {
    title: 'Time to update the\n Stessa Rent Pay app',
    message: 'Check out the newest features\n in Stessa Rent Pay\n by downloading the free update.',
    confirmationButtonText: 'Update',
    cancelationButtonText: 'Close',
  },
};
