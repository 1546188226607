export const AutopayConfirmation = {
  subscription: {
    authorized_payment_amount: 'Authorized Payment Amount',
    authorized_recurring_amount: 'Authorized Recurring Amount',
    authorized_date: 'Date Authorized',
    withdrawal_date: 'Withdrawal Day',
    funding_account: 'Funding Account',
    next_scheduled_date:
      'Your next payment is scheduled for {{next_scheduled_date}} and will be for {{month}} rent charge',
  },
  create: {
    title: 'Autopay Confirmation',
    subtitle: 'Thank you for setting up autopay!',
    body: 'The confirmation below verifies that you have authorized {{payee}} to initiate an electronic payment from your account.',
  },
  update: {
    title: 'Autopay Update Confirmation',
    subtitle: 'Update successful!',
    body: 'Your autopay has been updated. Here is a summary of your autopay information.',
  },
};
