import React from 'react';
import { Select } from 'native-base';
import { StyleSheet, View } from 'react-native';
import { h6 } from 'assets/styles/app';
import { BasicSelectFCInterface } from 'types/ComponentsTypes';

const BasicSelect: React.FC<BasicSelectFCInterface> = ({
  selected,
  placeholder,
  items,
  testID,
  handleChange,
}): JSX.Element => {
  return (
    <View testID={testID}>
      {items.length > 0 && (
        <Select
          style={styles.select}
          selectedValue={selected}
          accessibilityLabel={placeholder}
          placeholder={placeholder}
          onValueChange={handleChange}
          testID={testID && `${testID}-input`}
        >
          {items.map(item => (
            <Select.Item
              key={item.value}
              label={item.label}
              value={item.value}
            />
          ))}
        </Select>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  select: {
    height: 42,
    fontSize: h6,
  },
});

export default BasicSelect;
