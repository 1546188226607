import { UserTraits } from '@segment/analytics-react-native';

export enum SEGMENT_EVENTS {
  TENANT_AUTHORIZED_RENT_PAYMENT = 'tenantAuthorizedRentPayment',
  TENANT_BEGAN_PAYMENT_METHOD_FLOW = 'tenantBeganPaymentMethodFlow',
  TENANT_ADDED_PAYMENT_METHOD = 'tenantAddedPaymentMethod',
  TENANT_EDITS_FEATURE = 'tenantEditsFeature',
  TENANT_PAYMENT_INITIATED = 'tenantPaymentInitiated',
}

type tenantBeganPaymentMethodFlow = {
  provider?: string;
  account_type?: string;
  date_method_launched?: string;
};

type tenantAddedPaymentMethod = tenantBeganPaymentMethodFlow & {
  account_name: string;
  account_id: string;
  connection_type: 'manual' | 'instant';
  landlord_uid: string;
}


type tenantAuthorizedRentPayment = {
  processing_account_id: string;
  amount: string;
  charge_date: string;
  date_authorized: string;
  autopay: boolean;
};

type tenantAuthorizedRentRecurringPayment = tenantAuthorizedRentPayment & {
  withdrawal_day: number;
};

type tenantEditsFeature = {
  date_feature_edited: string;
  address: string;
  withdrawal_day: string;
  next_scheduled_date: string;
  landlord_uid: string;
  recurring_amount: string;
  funding_account: string;
  feature?: string;
  unit: string;
};

export type SegmentPayloadType =
  | tenantBeganPaymentMethodFlow
  | tenantAddedPaymentMethod
  | tenantAuthorizedRentRecurringPayment
  | tenantAuthorizedRentPayment
  | tenantEditsFeature;

export type SendEvent = (
  name: SEGMENT_EVENTS,
  segmentPayload?: SegmentPayloadType,
) => void;

export type IdentifyEvent = (
  userId: string,
  userTraits?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    address?: string;
    phone?: number | string;
    landLord?: string;
  },
) => void;

export type UserTraitsUpdated = Exclude<UserTraits, 'address'> & {
  address: string | undefined;
};
