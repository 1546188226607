import React from 'react';
import { Text } from 'react-native';
import DataTable from '@components/table/Table';
import { StyleSheet } from 'react-native';
import { successColor } from '@styles/app';
import { secondaryColor, textMargin } from '@assets/styles/app';
import { PaymentHistoryTableRowFCInterface } from '@ownTypes/RentPayTypes';
import PaymentHistoryTableRowWrapper from './PaymentHistoryTableRow.wrapper';
import { isMobileBrowser } from '@helpers/appHelper';
import { longHeaderStyle, shortHeaderStyle } from '@styles/app';
const TODAY_DATE = new Date();

const PaymentHistoryTableRow: React.FC<PaymentHistoryTableRowFCInterface> = ({
  payment,
}): JSX.Element => {
  const isFuturePayment = new Date(payment.date) > TODAY_DATE;

  return (
    <PaymentHistoryTableRowWrapper payment={payment}>
      <DataTable.TableItem
        style={shortHeaderStyle}
        testID="rent-pay-ledger-cells"
      >
        {payment.date}
      </DataTable.TableItem>
      <DataTable.TableItem
        style={longHeaderStyle}
        testID="rent-pay-ledger-cells"
      >
        {payment.name}
        {isFuturePayment && (
          <Text style={styles.pendingText}>
            {isMobileBrowser && '\n'}pending
          </Text>
        )}
      </DataTable.TableItem>
    </PaymentHistoryTableRowWrapper>
  );
};

const styles = StyleSheet.create({
  incomingPrice: {
    color: successColor,
  },
  pendingText: {
    color: secondaryColor,
    fontStyle: 'italic',
    marginLeft: textMargin,
  },
});

export default PaymentHistoryTableRow;
