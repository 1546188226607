import React, { FC } from 'react';
import DataTable from '@components/table/Table';
import PaymentHistoryTableWrapper from './PaymentHistoryTable.wrapper';
import { longHeaderStyle, shortHeaderStyle } from '@styles/app';

const PaymentHistoryTable: FC = (): JSX.Element => {
  return (
    <PaymentHistoryTableWrapper>
      <DataTable.TableItem
        style={shortHeaderStyle}
        testID="payment-history-headers"
      >
        Date
      </DataTable.TableItem>
      <DataTable.TableItem
        style={longHeaderStyle}
        testID="payment-history-headers"
      >
        Name
      </DataTable.TableItem>
    </PaymentHistoryTableWrapper>
  );
};

export default PaymentHistoryTable;
