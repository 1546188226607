import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { UserStateInterface } from '@ownTypes/StateTypes';
import { UserType } from '@ownTypes/reducerTypes/UserTypes';
import { ObjectType } from '@ownTypes/GenericTypes';
import { TransactionType } from '@ownTypes/TransactionTypes';
import { SubscriptionResponseType } from '@ownTypes/reducerTypes/SubscriptionTypes';

const initialState: UserStateInterface = {
  data: {
    id: 0,
    payee: '',
    address: '',
    auth0_id: '',
    email: '',
    name: '',
    phone: '',
    unit: '',
    status: '',
    uid: '',
    landlord_uid: '',
    rent_due_date: '',
    intercom_hash_uid: null,
    rent_amount_cents: 0,
  },
  token: '',
  refreshToken: '',
  ldFlags: {},
  paymentSubscription: null,
  paymentSubscriptionError: null,
  paymentSubscriptionWithdrawalDateOptions: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(
      state: WritableDraft<UserStateInterface>,
      action: PayloadAction<UserType>,
    ) {
      if (action?.payload) {
        state.data = action.payload;
      }
    },
    setUserToken(
      state: WritableDraft<UserStateInterface>,
      action: PayloadAction<string>,
    ) {
      state.token = action.payload;
    },
    setRefreshToken(
      state: WritableDraft<UserStateInterface>,
      action: PayloadAction<string>,
    ) {
      state.refreshToken = action.payload;
    },
    setLDFlags(
      state: WritableDraft<UserStateInterface>,
      action: PayloadAction<ObjectType>,
    ) {
      state.ldFlags = action.payload;
    },
    deleteUserToken(state: WritableDraft<UserStateInterface>) {
      state.token = '';
    },
    setSubscription(
      state: WritableDraft<UserStateInterface>,
      action: PayloadAction<TransactionType<SubscriptionResponseType>>,
    ) {
      state.paymentSubscription = action.payload;
    },
    setSubscriptionError(
      state: WritableDraft<UserStateInterface>,
      action: PayloadAction<SubscriptionResponseType>,
    ) {
      state.paymentSubscriptionError = action.payload;
    },
    setSubscriptionWithdrawalDateOptions(
      state: WritableDraft<UserStateInterface>,
      action: PayloadAction<ObjectType>,
    ) {
      state.paymentSubscriptionWithdrawalDateOptions = action.payload;
    },
  },
});

export const {
  setUserData,
  setUserToken,
  deleteUserToken,
  setLDFlags,
  setSubscription,
  setSubscriptionError,
  setRefreshToken,
  setSubscriptionWithdrawalDateOptions,
} = userSlice.actions;

export default userSlice.reducer;
