import React, { useRef } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import {
  defaultGreyColor,
  defaultMargin,
  defaultSectionPadding,
  successColor,
} from '@styles/app';
import DefaultButton from '@components/buttons/DefaultButton';
import { AutopayConfirmationFCInterface } from '@ownTypes/AutopayTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { setLastPaymentResponse } from '@reducers/PaymentsReducer';
import { StoreInterface } from '@ownTypes/StoreType';
import { TRANSACTION_TYPES } from '@ownTypes/TransactionTypes';
import useSelectableAccounts from '@hooks/useSelectableAccounts';
import { setSubscription } from '@reducers/UserReducer';
import Update from './Update';
import Create from './Create';

const Confirmation: React.FC<AutopayConfirmationFCInterface> = ({
  handleClose,
}): JSX.Element => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const {
    user: { data: userData, paymentSubscription },
    accounts: { list: accounts },
  } = useSelector((state: StoreInterface) => state);

  const [fundingAccount, setFundingAccount] = React.useState(null);
  const [nextScheduledMonth, setNextScheduledMonth] = React.useState<Date>();
  const onlySelectableAccounts = useSelectableAccounts(accounts);

  const paymentSubscriptionReference = useRef({ ...paymentSubscription });

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', () => {
      dispatch(setLastPaymentResponse(null));
    });
    return unsubscribe;
  }, [navigation, dispatch]);

  React.useEffect(() => {
    if (
      paymentSubscription &&
      paymentSubscription.type !== TRANSACTION_TYPES.NOOP &&
      onlySelectableAccounts.size > 0
    ) {
      const account = onlySelectableAccounts.find(
        ({ account_id }) => account_id === paymentSubscription.data.account_id,
      );
      setFundingAccount(account);
      const scheduledMonth = new Date(paymentSubscription.data?.next_scheduled_date);
      scheduledMonth.setDate(scheduledMonth.getDate() - paymentSubscription.data?.withdrawal_offset);
      setNextScheduledMonth(scheduledMonth);
    }
  }, [paymentSubscription, onlySelectableAccounts]);

  const ctaClose = () => {
    dispatch(
      setSubscription({
        ...paymentSubscription,
        type: TRANSACTION_TYPES.NOOP,
      }),
    );

    if (Platform.OS !== 'web') {
      const ROUTES = require('navigation/routes').default;
      navigation.navigate(ROUTES.RENT_PAY.name);
    } else {
      handleClose();
    }
  };

  return (
    <View>
      {paymentSubscription &&
      paymentSubscriptionReference.current.type ===
        TRANSACTION_TYPES.CREATED ? (
        <Create
          userData={userData}
          paymentSubscription={paymentSubscription.data}
          fundingAccount={fundingAccount}
          nextScheduledMonth={nextScheduledMonth}
          styles={styles}
          testID="autopay-confirmation-create"
        />
      ) : (
        <Update
          paymentSubscription={paymentSubscription.data}
          fundingAccount={fundingAccount}
          nextScheduledMonth={nextScheduledMonth}
          styles={styles}
          testID="autopay-confirmation-update"
        />
      )}

      <View style={styles.buttonsWrapper}>
        <DefaultButton testID="close-confirmation-button" title="Close" handlePress={ctaClose} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  paymentInfoWrapper: {
    marginVertical: defaultMargin,
    paddingHorizontal: defaultSectionPadding,
    paddingBottom: defaultMargin,
    borderColor: defaultGreyColor,
    borderBottomWidth: 1,
    borderStyle: 'dashed',
  },
  thankYouText: {
    marginBottom: 30,
    color: successColor,
  },
  button: {
    marginRight: defaultMargin,
  },
  buttonsWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default Confirmation;
