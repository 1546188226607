import { call, put } from 'redux-saga/effects';
import { fetchAccounts } from '@reducers/AccountsReducer';
import Request from '@services/api/index';
import { API_ACCOUNTS } from '@store/config/apiUrls';
import { List } from 'immutable';
import { APIResolveResponse } from '@ownTypes/ApiTypes';
import { AccountType } from '@ownTypes/reducerTypes/AccountTypes';

const { delete: deleteMethod, get } = Request();

export function* handleDeleteAccount(payload: {
  type: string;
  data: {
    account_id: string;
  };
  successCallback: () => void;
  errorCallback: () => void;
}) {
  try {
    const { account_id } = payload.data;

    if (!account_id) {
      throw new Error('No account id found.');
    }

    const response: APIResolveResponse<string> = yield call(() =>
      deleteMethod(`${API_ACCOUNTS}/${account_id}`),
    );
    if (response.status === 204) {
      payload.successCallback();
    } else {
      payload.errorCallback();
    }
  } catch (err) {
    payload.errorCallback();
    console.error(err);
  }
}

export function* handleFetchAccounts(action: {
  type: string;
  callback: (arg0: List<unknown>) => void;
  onError: (arg0: any) => void;
}) {
  try {
    const response: APIResolveResponse<{ accounts: AccountType[] }> =
      yield call(() => get(API_ACCOUNTS));

    if (response?.data?.accounts) {
      yield put(fetchAccounts(response.data.accounts));

      if (typeof action.callback === 'function') {
        action.callback(List(response.data.accounts));
      }
    }
  } catch (err) {
    console.error(err);
    yield action.onError(err);
  }
}
