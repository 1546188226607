import { call, put, delay } from 'redux-saga/effects';
import {
  fetchPayments,
  setLastPaymentResponse,
  setPaymentError,
} from '@reducers/PaymentsReducer';
import { fetchLoggedUser } from './UserHandler';
import Request from '@services/api/index';
import { API_LEDGER, API_PAYMENTS } from '@store/config/apiUrls';
import { APIResolveResponse } from '@ownTypes/ApiTypes';
import {
  TransactionResponseType,
  TransactionType,
} from '@ownTypes/reducerTypes/PaymentTypes';
import { PaymentPayloadInterface } from '@ownTypes/reducerTypes/PaymentTypes';
import * as RootNavigation from '@navigation/rootNavigation';
import ROUTES from '@navigation/routes';

const { get, post } = Request();

export function* handleFetchPayments() {
  try {
    const response: APIResolveResponse<{ ledger: TransactionType[] }> =
      yield call(() => get(API_LEDGER));

    if (response) {
      yield put(fetchPayments(response));
    }
  } catch (err) {
    RootNavigation.navigate(ROUTES.ERROR_PAGE.name, {
      error: 'Ledger api error',
      errorDescription: 'Please try again later',
    });
    console.error(err);
  }
}

export function* handleAuthorizePayment({
  payload,
}: {
  type: string;
  payload: PaymentPayloadInterface;
}) {
  try {
    const response: APIResolveResponse<TransactionResponseType> = yield call(
      () => post(API_PAYMENTS, payload),
    );
    if (response) {
      yield delay(500);
      yield handleFetchPayments();
      yield put(setLastPaymentResponse(response?.data));
      yield fetchLoggedUser();
    }
  } catch (err) {
    yield put(setPaymentError(err.message));
    console.error(err);
  }
}
