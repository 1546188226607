import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { h5, defaultGreyColor } from '@styles/app';
import { PageTitleFCInterface } from '@ownTypes/StructureTypes';

const PageTitle: React.FC<PageTitleFCInterface> = ({ title }): JSX.Element => {
  return (
    <View style={styles.container}>
      <Text style={styles.title} testID="page-title">
        {title}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 2,
    justifyContent: 'center',
  },
  title: {
    fontSize: h5,
    color: defaultGreyColor,
    fontWeight: '600',
    textAlignVertical: 'center',
  },
});

export default PageTitle;
