import axios from 'axios';

const AxiosInstance = axios.create({
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Headers': '*',
  },
});

AxiosInstance.interceptors.request.use(
  config => {
    const store = require('@store/index').default;
    const AUTH_TOKEN = store.getState()?.user?.token;

    if (!AUTH_TOKEN) {
      throw new Error('Could not get Bearer token.');
    }

    config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;

    return config;
  },
  error => {
    console.error(error);
    throw new Error('Something went wrong.');
  },
);

export default AxiosInstance;
