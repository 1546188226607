import React, { useState } from 'react';
import {
  TouchableOpacity,
  Text,
  View,
  ActivityIndicator,
  StyleSheet,
} from 'react-native';
import { button, buttonDisabled } from '@styles/app';
import { BasicButtonFCInterface } from '@ownTypes/ButtonTypes';

const BasicButton: React.FC<BasicButtonFCInterface> = ({
  style,
  handlePress,
  title,
  testID,
  disabled,
  loading,
  variant: customVariant,
}): JSX.Element => {
  const [variant, setVariant] = useState(button);

  React.useEffect(() => {
    if (customVariant) {
      setVariant(customVariant);
    }

    if (disabled) {
      setVariant(buttonDisabled);
    }
  }, [disabled, customVariant]);

  return (
    <View style={style?.wrapper}>
      <TouchableOpacity
        style={[variant, style]}
        disabled={disabled}
        onPress={handlePress}
        testID={`${testID}-box`}
      >
        <Text style={[variant.text, style?.text]} testID={`${testID}-text`}>
          {title ? title : 'Submit'}
        </Text>
        {loading && (
          <ActivityIndicator
            style={styles.activityIndicator}
            color={variant.loadingColor}
          />
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  activityIndicator: {
    marginLeft: 6,
  },
});

export default BasicButton;
