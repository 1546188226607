import {
  Alert,
  PanResponder,
  PanResponderInstance,
  Platform,
} from 'react-native';
import store from 'store';
import { useAuth0 } from '@auth0/auth0-react';
import { setRefreshToken, setUserToken } from 'store/reducers/UserReducer';
import auth0 from '@services/auth0';
import useHandleLogout from './useHandleLogoutCTA';
import { useState } from 'react';

const interval = 15; // minutes. Checking interval must be always smaller then the refresh token lifetime.
const intervalInMiliseconds = interval * 60000;
const alertMessage =
  "Session expired due to inactivity. You'll have to login again to continue.";
export default useAuth0SessionManagement = (): {
  setEvent: () => PanResponderInstance;
  clearEvent: () => void;
} => {
  const { getAccessTokenSilently } = useAuth0();
  const handleLogout = useHandleLogout();
  const [lastActivity, setLastActivity] = useState<number>(0);
  let intervalId: null | any = null;
  let timeoutId: null | any = null;

  const calculateTimeDifference = () => {
    let difference = 0;

    if (lastActivity) {
      difference = Math.abs(
        Math.round(
          ((new Date().getTime() - new Date(lastActivity).getTime()) /
            1000 /
            60 +
            Number.EPSILON) *
            100,
        ) / 100,
      );
    }
    return !difference || difference >= interval;
  };

  const checkAuth0Token = async () => {
    try {
      if (!calculateTimeDifference()) {
        let newToken = '';
        if (Platform.OS !== 'web') {
          const refreshToken = store.getState().user.refreshToken;

          if (!refreshToken) {
            // eslint-disable-next-line no-new
            new Error('Could not find the refresh token.');
          }
          const response = await auth0.auth.refreshToken({
            refreshToken,
            scope: 'offline_access',
          });
          newToken = response?.accessToken;
          store.dispatch(setRefreshToken(response?.refreshToken));
        } else {
          newToken = await getAccessTokenSilently({
            ignoreCache: true,
            audience: process.env.AUTH0_AUDIENCE,
            scope: 'offline_access',
            timeoutInSeconds: 15,
          });
        }

        if (newToken) {
          store.dispatch(setUserToken(newToken));
        }
      }
    } catch (e) {
      logoutUser();
    }
  };

  const setCheckAuth0Token = () => {
    intervalId = setInterval(checkAuth0Token, intervalInMiliseconds);
    setNewSessionTimeout();
  };

  const setNewSessionTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(logoutUser, intervalInMiliseconds);
  };

  const logoutUser = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (Platform.OS === 'web') {
      // eslint-disable-next-line no-alert
      alert(alertMessage);
      handleLogout();
    } else {
      Alert.alert(
        'Session expired due to inactivity.',
        "You'll have to login again to continue.",
        [
          {
            text: 'Ok',
            onPress: () => {
              handleLogout();
            },
          },
        ],
      );
    }
  };

  return {
    setEvent: () => {
      setCheckAuth0Token();
      return PanResponder.create({
        onStartShouldSetPanResponderCapture: () => {
          setLastActivity(new Date().getTime());
          setNewSessionTimeout();
          return false;
        },
      });
    },
    clearEvent: () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    },
  };
};
