import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { defaultFontWeight, primaryColor } from '@styles/app';
import { BankDataFCInterface } from '@ownTypes/MakePaymentTypes';
import BankAccountNumber from './BankAccountNumber';
import { ACCOUNT_STATUS } from '@ownTypes/AccountStatusTypes';

const BankData: React.FC<BankDataFCInterface> = ({
  data,
  handleVerifyAccount,
  handleRefreshAccount,
}) => {
  return (
    <View style={styles.container}>
      {data.verification_status === ACCOUNT_STATUS.PENDING_VERIFICATION && (
        <TouchableOpacity onPress={() => handleVerifyAccount(data.account_id)}>
          <Text testID="verify-manual-account-link" style={styles.verifyLink}>
            Verify my bank account
          </Text>
        </TouchableOpacity>
      )}
      {(data.verification_status === ACCOUNT_STATUS.LOGIN_REQUIRED ||
        data.verification_status === ACCOUNT_STATUS.PENDING_EXPIRATION) && (
        <TouchableOpacity onPress={() => handleRefreshAccount(data.account_id)}>
          <Text testID="refresh-connection-link" style={styles.verifyLink}>
            Refresh connection
          </Text>
        </TouchableOpacity>
      )}
      <Text style={styles.bankName} testID="account-bank-name">
        {data.name}
      </Text>
      <BankAccountNumber subtype={data.subtype} mask={data.mask} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 2,
    marginHorizontal: 10,
  },
  bankName: {
    fontWeight: defaultFontWeight,
  },
  verifyLink: {
    color: primaryColor,
  },
});

export default BankData;
