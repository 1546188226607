import store from 'store';
import { PLAID_ACTION_TYPES } from '@sagas/actions/PlaidActions';
import { ACCOUNTS_ACTION_TYPES } from '@sagas/actions/AccountsActions';
import { sendEvent } from '@services/segment';
import { storeStateService } from '@services/storeStateService';
import { REDUCERS } from '@ownTypes/StoreTypes';
import { ACCOUNT_STATUS } from '@ownTypes/AccountStatusTypes';
import { LinkAccount } from './linkAccount';
import { SegmentPayloadType, SEGMENT_EVENTS } from '@ownTypes/SegmentTypes';

const BANK_SUPPLIER = 'plaid';
const segmentPayload: SegmentPayloadType = {
  provider: BANK_SUPPLIER,
  account_type: 'bank',
};
const currentDate = new Date().toISOString();

// When we receive accountId this method is used for Manual Verification of a Plaid Account
export const createAccount: (accountId?: string, landlord_uid?: string, callback?: (err?) => void) => Promise<any> =
  (accountId, landlord_uid, callback) => {
    return new Promise(
      (resolve: (arg?: any) => void, reject: (err?: string) => void) => {
        store.dispatch({
          type: PLAID_ACTION_TYPES.PLAID_TOKEN,
          ...(accountId && {
            data: {
              accountId,
            },
          }),
        });

        storeStateService(
          plaidToken => {
            if (!accountId) {
              sendEvent(SEGMENT_EVENTS.TENANT_BEGAN_PAYMENT_METHOD_FLOW, {
                ...segmentPayload,
                date_method_launched: currentDate,
              });
            }
            const onSuccess: (public_token: string, metadata: any) => void = (
              public_token,
              metadata,
            ) => {
              if (metadata?.accounts?.length > 0) {
                const account = metadata.accounts[0];
                const data = {
                  public_token,
                  account: {
                    ...account,
                    verification_status:
                      account?.verification_status || account?.verificationStatus,
                  },
                  institution_id:
                    metadata?.institution?.institution_id ||
                    metadata?.institution?.id,
                };

                store.dispatch({
                  type: PLAID_ACTION_TYPES.LINK_ACCOUNTS,
                  data,
                  callback: () => {
                    if (!accountId) {
                      sendEvent(
                        SEGMENT_EVENTS.TENANT_ADDED_PAYMENT_METHOD,
                        {
                          ...segmentPayload,
                          account_name: metadata?.institution?.name ?? '',
                          account_id: account.id,
                          connection_type:
                            account.verification_status ===
                            ACCOUNT_STATUS.PENDING_VERIFICATION
                              ? 'manual'
                              : 'instant',
                          landlord_uid: landlord_uid ?? '',
                        },
                      );
                    }

                    store.dispatch({
                      type: ACCOUNTS_ACTION_TYPES.FETCH_ACCOUNTS,
                      onError: onExit,
                      callback: onResolve,
                    });
                  },
                });
              }
            };

            const onResolve = () => {
              resolve();
              if (typeof callback === 'function') {
                callback();
              }
            };

            const onExit = err => {
              reject(err);
              if (typeof callback === 'function') {
                callback(err);
              }
            };

            LinkAccount(plaidToken, onSuccess, onExit);
          },
          REDUCERS.ACCOUNTS,
          'plaidToken',
        );
      },
    );
};
