import React, { useCallback, useState } from 'react';
import { Platform, StyleSheet, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { StoreInterface } from '@ownTypes/StoreType';
import CustomerInfo from '@views/RentPay/CustomerInfo';
import Accounts from '@views/MakePayment/Accounts/Accounts';
import AmountWrapper from '@views/MakePayment/AmountWrapper';
import BasicInfo from '@components/BasicInfo';
import PrimaryButton from '@components/buttons/PrimaryButton';
import WithdrawalDate from './WithdrawalDateSelect';
import {
  button_lg,
  defaultGreyColor,
  defaultMarginSmall,
  defaultSectionPadding,
  h6,
} from '@assets/styles/app';
import { Box } from 'native-base';
import BaseOverlay from '@components/BaseOverlay';
import { SUBSCRIPTION_ACTION_TYPES } from '@store/sagas/actions/SubscriptionActions';
import { checkInputBalance, englishOrdinalSuffix, getLastBalance } from '@helpers/appHelper';
import {
  getISOString,
  getDueDayOffsetText,
  getCurrentISOString,
} from '@helpers/dateHelper';
import { sendEvent } from '@services/segment';
import { centsToDollars, filterCurrency } from '@helpers/currencyHelper';
import { SEGMENT_EVENTS } from '@ownTypes/SegmentTypes';
import SetAsRecurringWrapper from './SetAsRecurringWrapper';
import {
  setMakePaymentOverlay,
  setSideModalToggle,
} from '@reducers/AppReducer';
import { SubscriptionStatus } from '@ownTypes/reducerTypes/SubscriptionTypes';
import { ALERT_TEXT } from '@lang/AlertText';
import CustomConfirmationModal from '@components/CustomConfirmationModal';
import useCTACancelAutopay from '@hooks/useCTACancelAutopay';
import Alert from '@components/Alert';
import { AlertType, ALERT_TYPES } from '@ownTypes/AlertTypes';
import { setSubscriptionError } from '@reducers/UserReducer';
import { TRANSACTION_TYPES } from '@ownTypes/TransactionTypes';
import { AutopayConfirmation } from '@lang/AutopayConfirmation';
import { AccountType } from '@ownTypes/reducerTypes/AccountTypes';
import BoldText from '@components/structure/BoldText';
import { Autopay as AutopayMsg } from '@lang/Autopay';
import ScrollView from '@components/ScrollView';

const AutoPay: React.FC<any> = ({ navigation }) => {
  const userData = useSelector((state: StoreInterface) => state.user.data);
  const paymentSubscription = useSelector(
    (state: StoreInterface) => state.user.paymentSubscription,
  );
  const paymentSubscriptionError = useSelector(
    (state: StoreInterface) => state.user.paymentSubscriptionError,
  );
  const makePaymentOverlay = useSelector(
    (state: StoreInterface) => state.app.makePaymentOverlay,
  );
  const payments = useSelector(
    (state: StoreInterface) => state.payments.payments,
  );

  const dispatch = useDispatch();
  const cancelAutopay = useCTACancelAutopay();
  const lastChargeAmount = userData?.rent_amount_cents;
  const [selectedWithdrawDay, setSelectedWithdrawDay] = useState(paymentSubscription?.data?.withdrawal_offset ?? '-3');
  const [amount, setAmount] = useState(
    paymentSubscription?.data?.amount_cents ?? lastChargeAmount,
  );
  const [error, setError] = useState('');
  const [recurring, setRecurring] = useState(false);
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<any>();

  React.useEffect(() => {
    if (
      paymentSubscription?.type === TRANSACTION_TYPES.UPDATED &&
      selectedAccount?.mask
    ) {
      sendEvent(SEGMENT_EVENTS.TENANT_EDITS_FEATURE, {
        date_feature_edited: getCurrentISOString(),
        address: userData.address,
        landlord_uid: userData.landlord_uid,
        withdrawal_day: getDueDayOffsetText(
          selectedWithdrawDay,
          englishOrdinalSuffix(paymentSubscription.data.withdrawal_date),
        ),
        recurring_amount: Number(centsToDollars(amount)).toFixed(2),
        feature: 'rent collection autopay',
        next_scheduled_date: getISOString(
          paymentSubscription.data.next_scheduled_date,
        ),
        funding_account: `****${selectedAccount.mask}`,
        unit: userData.unit,
      });
    }
    return () => {
      dispatch(setSubscriptionError(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSubscription]);

  React.useEffect(() => {
    if (paymentSubscription) {
      setRecurring(paymentSubscription.data?.status === 'active');
    }
  }, [paymentSubscription, setRecurring]);

  React.useEffect(() => {
    if (paymentSubscription?.data?.withdrawal_offset !== undefined) {
      setSelectedWithdrawDay(
        String(paymentSubscription.data?.withdrawal_offset),
      );
    }
  }, [paymentSubscription, setSelectedWithdrawDay]);

  React.useEffect((): void => {
    if (paymentSubscriptionError) {
      dispatch(setMakePaymentOverlay(false));

      setAlert({
        status: ALERT_TYPES.PAYMENT_ERROR.status,
        title: ALERT_TYPES.PAYMENT_ERROR.title,
        description: paymentSubscriptionError,
      });
    } else if (alert) {
      setAlert(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSubscriptionError, dispatch]);

  const goToConfirmationScreen = subscription => {
    if (
      subscription &&
      subscription?.type !== TRANSACTION_TYPES.NOOP &&
      Platform.OS !== 'web'
    ) {
      const confirmationTitle =
        subscription.type === TRANSACTION_TYPES.CREATED
          ? AutopayConfirmation.create.title
          : AutopayConfirmation.update.title;

      const ROUTES = require('navigation/routes').default;
      navigation.navigate(ROUTES.AUTOPAY_CONFIRMATION.name, {
        title: confirmationTitle,
      });
    }
  };

  const handleConfirm = () => {
    dispatch(setMakePaymentOverlay(true));
    dispatch(setSubscriptionError(null));

    cancelAutopay(paymentSubscription?.data?.id, () => {
      setShowConfirmationAlert(false);
      dispatch(setMakePaymentOverlay(false));

      if (Platform.OS !== 'web') {
        const ROUTES = require('navigation/routes').default;
        navigation.navigate(ROUTES.RENT_PAY.name);
      } else {
        dispatch(setSideModalToggle(false));
      }
    });
  };

  const getCurrentBalance = (): number => {
    const lastBalance = getLastBalance(payments);
    return lastBalance?.balance?.cents ?? 0;
  };

  const handleUpdateAutopay = (): void => {
    if (!error) {
      dispatch(setMakePaymentOverlay(true));
      dispatch(setSubscriptionError(null));

      dispatch({
        type: SUBSCRIPTION_ACTION_TYPES.UPDATE_SUBSCRIPTION,
        data: {
          id: paymentSubscription?.data?.id,
          payload: {
            account_id: selectedAccount?.account_id,
            withdrawal_offset: selectedWithdrawDay,
            amount_cents: amount,
            autopay_enabled: recurring,
            balance: getCurrentBalance(),
          },
        },
        callback: subscription => {
          goToConfirmationScreen(subscription);
          dispatch(setMakePaymentOverlay(false));
        },
      });
    }
  };

  const handleCreateAutopay = (): void => {
    if (!error) {
      dispatch(setMakePaymentOverlay(true));
      dispatch(setSubscriptionError(null));

      dispatch({
        type: SUBSCRIPTION_ACTION_TYPES.CREATE_SUBSCRIPTION,
        data: {
          payload: {
            account_id: selectedAccount?.account_id,
            withdrawal_offset: selectedWithdrawDay,
            amount_cents: amount,
            balance: getCurrentBalance(),
          },
        },
        callback: subscription => {
          goToConfirmationScreen(subscription);
          dispatch(setMakePaymentOverlay(false));
        },
      });
    }
  };

  const handleChange = useCallback(
    (type: 'account' | 'recurring' | 'amount' | 'withdraw') =>
      (newValue: AccountType | boolean | number | string): void => {
        const initialAccount = paymentSubscription?.data?.account_id;
        let accountChanged = initialAccount !== selectedAccount?.account_id;

        if (type === 'account' && typeof newValue === 'object') {
          setSelectedAccount(newValue);
          accountChanged = initialAccount !== newValue?.account_id;
        }

        if (type === 'recurring' && typeof newValue === 'boolean') {
          setRecurring(prev => {
            if (
              prev &&
              paymentSubscription?.data?.status === SubscriptionStatus.ACTIVE
            ) {
              setShowConfirmationAlert(true);
            }

            return !prev;
          });
        }

        const initialAmount =
          paymentSubscription?.data?.amount_cents ?? lastChargeAmount;
        let amountChanged = initialAmount !== amount;
        if (type === 'amount' && typeof newValue === 'number') {
          setError(checkInputBalance(newValue, undefined, lastChargeAmount));
          setAmount(newValue);
          amountChanged = initialAmount !== newValue;
        }

        const initialWithdraw = String(
          paymentSubscription?.data?.withdrawal_offset,
        );
        let withdrawChanged = initialWithdraw !== String(selectedWithdrawDay);
        if (type === 'withdraw' && typeof newValue === 'string') {
          setSelectedWithdrawDay(newValue);
          withdrawChanged = initialWithdraw !== newValue;
        }

        setIsDirty(accountChanged || amountChanged || withdrawChanged);
      },
    [
      amount,
      lastChargeAmount,
      paymentSubscription,
      selectedAccount,
      selectedWithdrawDay,
    ],
  );

  const deleteActiveAccountCallback = (): void => {
    dispatch(setMakePaymentOverlay(false));
    if (Platform.OS !== 'web') {
      const ROUTES = require('navigation/routes').default;
      navigation.navigate(ROUTES.RENT_PAY.name);
    } else {
      dispatch(setSideModalToggle(false));
    }
  };

  const messages = paymentSubscription ? AutopayMsg.update : AutopayMsg.create;

  const isDisabled =
    !isDirty ||
    !!error ||
    amount === 0 ||
    !selectedAccount ||
    selectedWithdrawDay === '' ||
    (!recurring && !paymentSubscription);

  const CustomerInfoComponent: JSX.Element = (
    <Text style={styles.info}>
      For <CustomerInfo address={userData?.address} unit={userData?.unit} />
    </Text>
  );

  return (
    <ScrollView
      style={styles.container}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.content}
      testID="setup-autopay-screen"
    >
      {!makePaymentOverlay && <Alert state={alert} setState={setAlert} />}
      <>
        {CustomerInfoComponent}
        <Accounts
          setAccount={handleChange('account')}
          setAlert={setAlert}
          selectedAccountId={paymentSubscription?.data?.account_id}
          deleteActiveAccountCallback={deleteActiveAccountCallback}
        />
        <Box style={styles.form}>
          <SetAsRecurringWrapper
            isRecurring={recurring}
            handleRecurringPayment={handleChange('recurring')}
            testID="recurring-switch"
          />
          <AmountWrapper
            amount={amount}
            setPaymentAmount={handleChange('amount')}
            inputErrorMessage={error}
          />
          <WithdrawalDate
            selectedWithdrawDay={selectedWithdrawDay}
            handleChange={handleChange('withdraw')}
          />
        </Box>
        {paymentSubscription ? (
          <PrimaryButton
            testID="update-autopay-button"
            style={button_lg}
            disabled={isDisabled}
            title="Update"
            handlePress={handleUpdateAutopay}
          />
        ) : (
          <PrimaryButton
            testID="authorize-autopay-button"
            style={button_lg}
            disabled={isDisabled}
            title="Authorize Autopay"
            handlePress={handleCreateAutopay}
          />
        )}
        <BasicInfo>
          {messages.info1.replace('{{payee}}', userData?.payee || 'Stessa').replace(
            '{{amount}}',
            filterCurrency(String(centsToDollars(amount))),
          )}
          <BoldText>{messages.info1_bold}</BoldText>.
        </BasicInfo>
        <BasicInfo>{messages.info2}</BasicInfo>
        <BasicInfo>{messages.info3}</BasicInfo>
      </>
      <CustomConfirmationModal
        handleClose={() => {
          setShowConfirmationAlert(false);
          setRecurring(true);
        }}
        handleConfirm={handleConfirm}
        showAlert={showConfirmationAlert}
        title={ALERT_TEXT.CANCEL_AUTOPAY.title}
        confirmationButtonText={
          ALERT_TEXT.CANCEL_AUTOPAY.confirmationButtonText
        }
        message={ALERT_TEXT.CANCEL_AUTOPAY.message}
        confirmation={ALERT_TEXT.CANCEL_AUTOPAY.confirmation}
        icon={'info-circle'}
      />
      {makePaymentOverlay && <BaseOverlay />}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: defaultMarginSmall,
    paddingHorizontal: defaultSectionPadding,
  },
  content: {
    paddingBottom: Platform.OS === 'web' ? 0 : 30,
  },
  form: {
    marginBottom: defaultMarginSmall,
  },
  info: {
    fontSize: h6,
    color: defaultGreyColor,
    marginTop: defaultMarginSmall,
  },
});

export default AutoPay;
