import React, { useCallback, useEffect } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Platform, Alert } from 'react-native';
import ROUTES from '@navigation/routes';
import { useSelector } from 'react-redux';
import Header from '@components/structure/Header';
import { StoreInterface } from '@ownTypes/StoreType';
import { useVersionCheck } from '@hooks/useVersionCheck';
import LoadingSpinner from '@components/LoadingSpinner';
import { ALERT_TEXT } from '@lang/AlertText';
import navigateTo from '@helpers/navigateTo';
import { setDataDogUser } from '@services/datadog';

const RootStack = createNativeStackNavigator();
const navigatorOptions = {
  screenOptions: {
    header: props => <Header {...props} />,
  },
};

export default () => {
  const token = useSelector((state: StoreInterface) => state?.user.token);
  const ldFlags = useSelector((state: StoreInterface) => state?.user.ldFlags);
  const userData = useSelector((state: StoreInterface) => state.user.data);

  const { RECOMMENDED_APP_UPDATE } = ALERT_TEXT;
  const { versionChecked, recommendedUpdate, requiredUpdate, storeUrl } =
    useVersionCheck();

  const handleRecommendedUpdate = useCallback(() => {
    Alert.alert(RECOMMENDED_APP_UPDATE.title, RECOMMENDED_APP_UPDATE.message, [
      {
        text: RECOMMENDED_APP_UPDATE.cancelationButtonText,
        onPress: () => null,
      },
      {
        text: RECOMMENDED_APP_UPDATE.confirmationButtonText,
        onPress: () => navigateTo(storeUrl),
      },
    ]);
  }, [storeUrl, RECOMMENDED_APP_UPDATE]);

  useEffect(() => {
    if (recommendedUpdate) {
      handleRecommendedUpdate();
    }
  }, [recommendedUpdate, handleRecommendedUpdate]);

  React.useEffect(() => {
    if (userData) {
      setDataDogUser({
        id: userData.uid,
      });
    }
  }, [userData]);

  return (
    <>
      <RootStack.Navigator {...navigatorOptions}>
        {!token ? (
          <RootStack.Screen
            name={ROUTES.LOGIN.name}
            component={ROUTES.LOGIN.component}
            options={{ header: () => false }}
          />
        ) : Platform.OS === 'web' ? (
          <RootStack.Screen
            name={ROUTES.RENT_PAY.name}
            component={ROUTES.RENT_PAY.component}
          />
        ) : !versionChecked ? (
          <RootStack.Screen
            name="Loading"
            component={LoadingSpinner}
            options={{ header: () => false }}
          />
        ) : (
          <>
            {requiredUpdate ? (
              <RootStack.Screen
                name={ROUTES.APP_UPDATE.name}
                component={ROUTES.APP_UPDATE.component}
                initialParams={{ storeUrl: storeUrl }}
                options={{ header: () => false }}
              />
            ) : (
              <>
                <RootStack.Screen
                  name={ROUTES.RENT_PAY.name}
                  component={ROUTES.RENT_PAY.component}
                />
                <RootStack.Screen
                  name={ROUTES.MAKE_PAYMENT.name}
                  component={ROUTES.MAKE_PAYMENT.component}
                />
                {ldFlags?.recurring_payment && (
                  <>
                    <RootStack.Screen
                      name={ROUTES.AUTOPAY.name}
                      component={ROUTES.AUTOPAY.component}
                    />
                    <RootStack.Screen
                      name={ROUTES.AUTOPAY_CONFIRMATION.name}
                      component={ROUTES.AUTOPAY_CONFIRMATION.component}
                    />
                  </>
                )}
                <RootStack.Screen
                  name={ROUTES.PAYMENT_CONFIRMATION.name}
                  component={ROUTES.PAYMENT_CONFIRMATION.component}
                />
              </>
            )}
          </>
        )}
        <>
          <RootStack.Screen
            name={ROUTES.ERROR_PAGE.name}
            component={ROUTES.ERROR_PAGE.component}
          />
        </>
      </RootStack.Navigator>
    </>
  );
};
