import React from 'react';
import PageSubtitle from '@components/PageSubtitle';
import { View, StyleSheet } from 'react-native';
import PaymentHistoryTable from './PaymentHistoryTable';

const PaymentHistory: React.FC = (): JSX.Element => {
  return (
    <View testID="payment-history">
      <PageSubtitle style={styles.title}>Payment History</PageSubtitle>
      <PaymentHistoryTable />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    marginTop: 20,
    marginBottom: 40,
  },
});

export default PaymentHistory;
