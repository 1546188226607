import React, { useState, useCallback } from 'react';
import { Platform, SafeAreaView, StyleSheet } from 'react-native';
import Accounts from './Accounts/Accounts';
import { defaultSectionPadding } from '@styles/app';
import AmountWrapper from './AmountWrapper';
import AuthorizePaymentButton from './AuthorizePaymentButton';
import { useSelector, useDispatch } from 'react-redux';
import {
  checkInputBalance,
  getDayFromDate,
  getLastBalance,
} from '@helpers/appHelper';
import { sendEvent } from '@services/segment';
import { PAYMENTS_ACTION_TYPES } from '@sagas/actions/PaymentsActions';
import { clearPaymentError } from '@reducers/PaymentsReducer';
import { setMakePaymentOverlay } from '@reducers/AppReducer';
import { v4 as uuid } from 'uuid';
import BaseOverlay from '@components/BaseOverlay';
import { DispatchType } from '@ownTypes/StoreType';
import { StoreInterface } from '@ownTypes/StoreType';
import { TransactionType } from '@ownTypes/reducerTypes/PaymentTypes';
import { PaymentPayloadInterface } from '@ownTypes/reducerTypes/PaymentTypes';
import { SegmentPayloadType, SEGMENT_EVENTS } from '@ownTypes/SegmentTypes';
import { AccountType } from '@ownTypes/reducerTypes/AccountTypes';
import { MakePaymentFCInterface } from '@ownTypes/MakePaymentTypes';
import Alert from '@components/Alert';
import { AlertType, ALERT_TYPES } from '@ownTypes/AlertTypes';
import { minValue } from '@helpers/appHelper';
import { SubscriptionStatus } from 'types/reducerTypes/SubscriptionTypes';
import { centsToDollars } from 'helpers/currencyHelper';
import { getCurrentISOString, getISOString } from 'helpers/dateHelper';
import ScrollView from '@components/ScrollView';

const MakePayment: React.FC<MakePaymentFCInterface> = ({ navigation }) => {
  const dispatch: DispatchType = useDispatch();
  const {
    payments: { paymentError, payments, lastPaymentResponse },
    app: { makePaymentOverlay },
    user: { data: userData, paymentSubscription },
  } = useSelector((state: StoreInterface) => state);
  const [selectedAccount, setSelectedAccount] = useState<AccountType | null>();
  const [amount, setAmount] = useState(0);
  const [defaultBalance, setDefaultBalance] = useState(0);
  const [disablePayment, setDisablePayment] = useState(false);
  const [paymentInitiated, setPaymentInitiated] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState('');
  const [alert, setAlert] = useState<AlertType | null>(null);

  React.useEffect((): void => {
    if (payments && payments.size > 0) {
      const lastPayment: TransactionType = getLastBalance(payments);
      if (lastPayment?.balance?.cents >= 0) {
        const paymentCents: number = lastPayment.balance.cents;

        if (
          defaultBalance > paymentCents ||
          (paymentInitiated && defaultBalance === paymentCents)
        ) {
          dispatch(setMakePaymentOverlay(false));
        }

        setAmount(paymentCents);
        setDefaultBalance(paymentCents);
      }
    }

    setPaymentInitiated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments, defaultBalance, dispatch]);

  React.useEffect((): void => {
    if (paymentError !== '') {
      setPaymentInitiated(false);
      dispatch(setMakePaymentOverlay(false));
      setAlert({
        status: ALERT_TYPES.PAYMENT_ERROR.status,
        title: ALERT_TYPES.PAYMENT_ERROR.title,
        description: paymentError,
      });
    } else if (alert) {
      setAlert(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentError, dispatch]);

  React.useEffect(() => {
    if (
      payments.size === 0 ||
      !amount ||
      minValue > amount ||
      amount > defaultBalance ||
      !selectedAccount
    ) {
      return setDisablePayment(true);
    }
    return setDisablePayment(false);
  }, [payments, amount, defaultBalance, paymentError, selectedAccount]);

  React.useEffect(() => {
    if (lastPaymentResponse && Platform.OS !== 'web') {
      const ROUTES = require('navigation/routes').default;
      navigation.navigate(ROUTES.PAYMENT_CONFIRMATION.name);
    }
  }, [lastPaymentResponse, navigation]);

  const setAccount = useCallback((selectedOption: AccountType): void => {
    setSelectedAccount(selectedOption ? selectedOption : null);
  }, []);

  const setPaymentAmount = (value: number): void => {
    setInputErrorMessage(checkInputBalance(value, defaultBalance));

    setAmount(value);
  };

  const handleAuthorizePayment = (): void => {
    const segmentPaymentPayload: SegmentPayloadType = {
      processing_account_id: selectedAccount?.account_id,
      amount: Number(centsToDollars(amount)).toFixed(2),
      charge_date: getISOString(userData?.rent_due_date),
      date_authorized: getCurrentISOString(),
      autopay: paymentSubscription?.data?.status === SubscriptionStatus.ACTIVE,
      withdrawal_day: paymentSubscription
        ? getDayFromDate(paymentSubscription.data.withdrawal_date)
        : undefined,
    };

    const payload: PaymentPayloadInterface = {
      account_id: selectedAccount?.account_id,
      amount_cents: amount,
      description: 'Rent Pay',
      idempotency_key: uuid(),
    };

    sendEvent(
      SEGMENT_EVENTS.TENANT_AUTHORIZED_RENT_PAYMENT,
      segmentPaymentPayload,
    );

    setDisablePayment(true);
    setPaymentInitiated(true);
    dispatch(setMakePaymentOverlay(true));

    if (paymentError) {
      clearErrors();
    }

    dispatch({
      type: PAYMENTS_ACTION_TYPES.AUTHORIZE_PAYMENT,
      payload,
    });
  };

  const clearErrors = (): void => {
    dispatch(clearPaymentError());
    setAlert(null);
  };

  return (
    <SafeAreaView testID="make-payment-view">
      <ScrollView style={styles.container}>
        {!makePaymentOverlay && <Alert state={alert} setState={setAlert} />}
        <Accounts setAccount={setAccount} setAlert={setAlert} />
        <AmountWrapper
          amount={amount}
          setPaymentAmount={setPaymentAmount}
          inputErrorMessage={inputErrorMessage}
        />
        <AuthorizePaymentButton
          handleAuthorizePayment={handleAuthorizePayment}
          paymentInitiated={paymentInitiated}
          disabled={disablePayment}
          userData={userData}
        />
        {makePaymentOverlay && <BaseOverlay />}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: defaultSectionPadding,
  },
});

export default MakePayment;
