export interface TooltipInterface {
  children: JSX.Element;
  label: string;
  testID?: string;
  tooltipPosition?: string;
}

export enum TOOLTIP_POSTION {
  TOP = 'Top',
  BOTTOM = 'Bottom',
  LEFT = 'Left',
  RIGHT = 'Right',
}

export type ComponentCurrentPosition = {
  top: number;
  left: number;
};
