import axios from './Interceptors';
import ApiResponse from './Handler';
import { APIResolveResponse, APIRejectResponse } from '@ownTypes/ApiTypes';

const Request: () => {
  get: (
    route: string,
    params?: any,
  ) => Promise<APIResolveResponse<any> | APIRejectResponse>;
  post: (
    route: string,
    params?: any,
    headers?: any,
  ) => Promise<APIResolveResponse<any> | APIRejectResponse>;
  put: (
    route: string,
    params?: any,
    headers?: any,
  ) => Promise<APIResolveResponse<any> | APIRejectResponse>;
  delete: (
    route: string,
    params?: any,
  ) => Promise<APIResolveResponse<any> | APIRejectResponse>;
} = () => {
  const { resolve, reject } = ApiResponse();
  return {
    get: (route, params) => {
      return axios
        .get(route, { params })
        .then(response => {
          return resolve(response);
        })
        .catch(err => {
          return reject(err);
        });
    },

    post: (route, payload, headers) => {
      return axios
        .post(route, payload, headers)
        .then(response => {
          return resolve(response);
        })
        .catch(err => {
          return reject(err);
        });
    },

    put: (route, payload, headers) => {
      return axios
        .put(route, payload, headers)
        .then(response => {
          return resolve(response);
        })
        .catch(err => {
          return reject(err);
        });
    },

    delete: (route, payload) => {
      return axios
        .delete(route, payload)
        .then(response => {
          return resolve(response);
        })
        .catch(err => {
          return reject(err);
        });
    },
  };
};

export default Request;
