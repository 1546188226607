import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import HorizontalSeparator from './headerUtils/HorizontalSeparator';
import {
  defaultGreyColor,
  lightGrey,
  defaultSectionPadding,
} from '@styles/app';
import { Link, Image } from 'native-base';
import { isMobileBrowser } from '@helpers/appHelper';
import { linkStyle } from '@styles/app';

const copyright = require('@images/copyright.png');

const Footer: React.FC = (): JSX.Element => {
  const currentDate = () => {
    return new Date().getFullYear();
  };

  return (
    <View style={styles.container} testID="stessa-footer">
      <View style={styles.footerWrapper}>
        <View style={styles.footerInfo}>
          <Text style={styles.text} testID="stessa-text">
            Stessa
          </Text>
          <HorizontalSeparator />
          <Text style={styles.text} testID="roofstock-text">
            A Roofstock Company
          </Text>
        </View>
        <View style={styles.footerNavigation}>
          <Link
            _text={linkStyle}
            href="https://www.stessa.com/terms"
            isExternal
            isUnderlined={false}
            testID="terms"
          >
            Terms
          </Link>
          <HorizontalSeparator />
          <Link
            _text={linkStyle}
            href="https://www.stessa.com/privacy"
            isExternal
            isUnderlined={false}
            testID="privacy"
          >
            Privacy
          </Link>
          <HorizontalSeparator />
          <View style={styles.copyrightIconContainer}>
            <Image
              size={11}
              source={copyright}
              testID="copyright-symbol"
              alt="copyright"
            />
          </View>
          <Text style={styles.text} testID="copyright-text">
            {currentDate()} Stessa, Inc.
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'white',
    zIndex: 1,
  },
  footerWrapper: {
    flexDirection: 'row',
    padding: defaultSectionPadding,
    marginHorizontal: isMobileBrowser ? 0 : 10,
    color: defaultGreyColor,
    shadowColor: 'black',
    borderTopWidth: 1,
    borderColor: lightGrey,
  },
  footerInfo: {
    flex: 1,
    flexDirection: 'row',
  },
  footerNavigation: {
    flexDirection: 'row',
  },
  text: {
    color: defaultGreyColor,
    lineHeight: 24,
    fontSize: isMobileBrowser && 9,
  },
  copyrightIconContainer: {
    alignSelf: 'center',
    marginRight: 2,
  },
});

export default Footer;
