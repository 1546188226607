import { takeLatest, ForkEffect } from 'redux-saga/effects';
import {
  handleFetchPayments,
  handleAuthorizePayment,
} from '@sagas/handlers/PaymentsHandler';
import { PAYMENTS_ACTION_TYPES } from '@sagas/actions/PaymentsActions';

export default function* paymentsSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(PAYMENTS_ACTION_TYPES.FETCH_PAYMENTS, handleFetchPayments);
  yield takeLatest(
    PAYMENTS_ACTION_TYPES.AUTHORIZE_PAYMENT,
    handleAuthorizePayment,
  );
}
