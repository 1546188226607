import { all } from 'redux-saga/effects';
import userSaga from './UserSaga';
import paymentsSaga from './PaymentsSaga';
import accountsSaga from './AccountsSaga';
import plaidSaga from './PlaidSaga';
import subscriptionSaga from './SubscriptionSaga';

export default function* rootSaga() {
  yield all([
    userSaga(),
    paymentsSaga(),
    accountsSaga(),
    plaidSaga(),
    subscriptionSaga(),
  ]);
}
