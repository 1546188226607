import React, { useEffect, useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import { Stack, Box } from 'native-base';
import {
  secondaryColor,
  defaultGreyColor,
  lightGrey,
  successColor,
} from '@styles/app';
import PrimaryButton from '@components/buttons/PrimaryButton';
import BalanceInformation from './BalanceInformation';
import { BalanceWrapperFCInterface } from '@ownTypes/RentPayTypes';
import DefaultButton from '@components/buttons/DefaultButton';
import { useSelector } from 'react-redux';
import { StoreInterface } from 'types/StoreType';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import { formatDate } from '@helpers/dateHelper';

const BalanceWrapper: React.FC<BalanceWrapperFCInterface> = ({
  disabledPaymentButton,
  disabledAutopayButton,
  handleMakePayment,
  handleEditAutopay,
  payment,
  dueDate,
  paymentSubscription,
}): JSX.Element => {
  const isBalanceDue = payment?.balance?.cents && payment.balance.cents > 0;
  const ldFlags = useSelector((state: StoreInterface) => state.user.ldFlags);
  const userData = useSelector((state: StoreInterface) => state.user.data);
  const [withdrawalDate, setWithdrawalDate] = useState<string>();

  useEffect(() => {
    if (paymentSubscription?.data?.next_scheduled_date) {
      const futureDate = new Date(
        Date.parse(paymentSubscription.data.next_scheduled_date),
      );
      setWithdrawalDate(formatDate(futureDate, 'MM dd, yyyy'));
    }
  }, [paymentSubscription]);

  const userHasActiveLDRecurringAndSuccessfulPayments =
    ldFlags?.recurring_payment && userData?.successful_payments > 0;

  return (
    <Stack
      space="4"
      direction={['column', 'column', 'row']}
      style={styles.container}
    >
      <Box>
        {Object.keys(payment).length > 0 && (
          <BalanceInformation
            balance={payment.balance.cents}
            dueDate={dueDate}
            isBalanceDue={isBalanceDue}
          />
        )}
      </Box>

      {userHasActiveLDRecurringAndSuccessfulPayments && (
        <Stack
          space="2"
          w={['full', 'full', 'container']}
          direction={['column', 'column', 'row']}
          style={styles.autoPay}
          testID="autopay-wrapper"
        >
          {paymentSubscription && (
            <Box
              w={['full', 'container']}
              h={['auto', 'auto', '42']}
              style={styles.autoPayStatus}
            >
              <Text style={styles.autoPayStatusText}>Autopay:</Text>
              <Icon
                testID="autopay-enabled-icon"
                style={styles.autoPayStatusIconActive}
                name="check"
                size={8}
              />
            </Box>
          )}

          <Box w={['full', 'container', '56']}>
            <DefaultButton
              disabled={disabledAutopayButton}
              loading={disabledAutopayButton}
              title={paymentSubscription ? 'Edit Autopay' : 'Set Up Autopay'}
              handlePress={handleEditAutopay}
              testID={
                paymentSubscription
                  ? 'edit-autopay-button'
                  : 'set-up-autopay-button'
              }
            />

            {paymentSubscription && withdrawalDate && (
              <Text testID="autopay-next-payment-label" style={styles.scheduledPaymentDate}>
                Your next payment is scheduled for {withdrawalDate}
              </Text>
            )}
          </Box>
        </Stack>
      )}
      <Box w={['full', 'full', 'container']}>
        <PrimaryButton
          disabled={disabledPaymentButton || !isBalanceDue}
          loading={disabledPaymentButton}
          title={
            userHasActiveLDRecurringAndSuccessfulPayments
              ? 'Make One-Time Payment'
              : 'Make payment'
          }
          handlePress={handleMakePayment}
          testID={
            userHasActiveLDRecurringAndSuccessfulPayments
              ? 'make-one-time-payment-button'
              : 'make-a-payment-button'
          }
        />
      </Box>
    </Stack>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderColor: lightGrey,
    paddingBottom: 40,
    justifyContent: 'space-between',
  },
  autoPay: {
    alignSelf: 'flex-start',
    justifyContent: 'flex-end',
    marginStart: 'auto',
  },
  autoPayStatus: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  autoPayStatusText: {
    color: defaultGreyColor,
    marginRight: 2,
  },
  autoPayStatusIconActive: {
    color: 'white',
    width: 16,
    height: 16,
    lineHeight: 16,
    borderRadius: 8,
    overflow: 'hidden',
    textAlign: 'center',
    backgroundColor: successColor,
  },
  scheduledPaymentDate: {
    marginTop: 4,
    color: secondaryColor,
  },
});

export default BalanceWrapper;
