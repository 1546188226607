import { setAppErrors } from '@reducers/AppReducer';
import { APIResolveResponse, APIRejectResponse } from '@ownTypes/ApiTypes';

const GENERIC_ERROR = 'Unfortunately something went wrong! Please try again!';

const ApiResponse: () => {
  resolve: (data: any) => Promise<APIResolveResponse<any>>;
  reject: (data: any) => Promise<APIRejectResponse>;
} = () => {
  return {
    resolve: data => {
      const response = {
        success: true,
        status: data.status,
        message: data.statusText,
        headers: data.headers,
        data: data.data,
      };

      return Promise.resolve(response);
    },

    reject: data => {
      const responseData = data?.response?.data?.error;
      const response = {
        success: false,
        status: data?.response?.status || 500,
        message: responseData?.custom
          ? responseData?.message || responseData?.detail || GENERIC_ERROR
          : GENERIC_ERROR,
      };

      const store = require('@store/index').default;
      store.dispatch(setAppErrors(response));

      return Promise.reject(response);
    },
  };
};

export default ApiResponse;
