import React from 'react';
import BaseFormError from './BaseFormError';
import PageSubtitle from './PageSubtitle';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import {
  defaultGreyColor,
  defaultMarginSmall,
  h5,
  smallFontSize,
} from 'assets/styles/app';
import Tooltip from './Tooltip';
import { Box } from 'native-base';
import { StyleSheet, Text } from 'react-native';
import { FormGroupFCInterface } from 'types/ComponentsTypes';

const FormGroup: React.FC<FormGroupFCInterface> = ({
  label,
  labelTestId,
  children: InputComponent,
  infoTooltip,
  inputInfo,
  errorMessage,
  tooltipPosition,
}) => {
  return (
    <Box style={styles.container}>
      {label && (
        <PageSubtitle testID={labelTestId}>
          {label + ' '}
          {infoTooltip && (
            <Tooltip
              testID={'custom-tooltip'}
              tooltipPosition={tooltipPosition}
              label={infoTooltip}
            >
              <Icon name="info-circle" style={styles.tooltip} />
            </Tooltip>
          )}
        </PageSubtitle>
      )}
      {InputComponent ? InputComponent : null}
      {!!inputInfo && <Text testID="input-info" style={styles.info}>{inputInfo}</Text>}
      {!!errorMessage && <BaseFormError>{errorMessage}</BaseFormError>}
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: defaultMarginSmall,
  },
  info: {
    color: defaultGreyColor,
    fontSize: smallFontSize,
    marginTop: 5,
  },
  tooltip: {
    fontSize: h5,
    color: defaultGreyColor,
  },
});

export default FormGroup;
