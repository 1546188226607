import { defaultGreyColor } from 'assets/styles/app';
import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { BasicInfoFCInterface } from '@ownTypes/ComponentsTypes';

const BasicInfo: React.FC<BasicInfoFCInterface> = ({
  children: message,
  testID = '',
}) => {
  return (
    <Text style={styles.text} testID={testID}>
      {message}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    flexGrow: 1,
    fontStyle: 'italic',
    color: defaultGreyColor,
    marginTop: 10,
  },
});

export default BasicInfo;
