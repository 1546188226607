import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import ROUTES from '@navigation/routes';
import MakePayment from '@views/MakePayment';
import AutoPay from '@views/AutoPay';
import { SetSideModalFunction } from '@ownTypes/RentPayTypes';
import { StoreInterface } from '@ownTypes/StoreType';
import { Autopay as AutoPayMsg } from '@lang/Autopay';

const useCTAButtonByPlatform = (
  setSideModalComponent: SetSideModalFunction,
) => {
  const navigation = useNavigation();
  const {
    user: { paymentSubscription },
  } = useSelector((state: StoreInterface) => state);

  return (ctaName?: string) => {
    switch (ctaName) {
      case ROUTES.AUTOPAY.name:
        const title = paymentSubscription
          ? AutoPayMsg.update.title
          : AutoPayMsg.create.title;

        if (Platform.OS !== 'web') {
          return navigation.navigate(ROUTES.AUTOPAY.name, {
            title: title,
          });
        } else {
          setSideModalComponent({
            component: AutoPay,
            title: title,
          });
        }
        break;
      default:
        if (Platform.OS !== 'web') {
          navigation.navigate(ROUTES.MAKE_PAYMENT.name);
        } else {
          setSideModalComponent({
            component: MakePayment,
            title: title || ROUTES.MAKE_PAYMENT.name,
          });
        }
        break;
    }
  };
};

export default useCTAButtonByPlatform;
