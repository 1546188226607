import { call, put } from 'redux-saga/effects';
import { setPlaidToken } from '@reducers/AccountsReducer';
import Request from '@services/api/index';
import {
  API_PLAID_LINK_TOKEN,
  API_PLAID_LINK_ACCOUNTS,
} from '@store/config/apiUrls';
import { AccountType } from 'types/reducerTypes/AccountTypes';
import { APIResolveResponse } from 'types/ApiTypes';

const { get, post } = Request();

export function* handlePlaidToken({
  data: { accountId = null } = {},
}: any = {}) {
  try {
    accountId = accountId
      ? {
          account_id: accountId,
        }
      : null;
    const response: APIResolveResponse<{ link_token: string }> = yield call(
      () => get(API_PLAID_LINK_TOKEN, accountId),
    );
    if (response?.data?.link_token) {
      yield put(setPlaidToken(response.data.link_token));
    }
  } catch (err) {
    console.error(err);
  }
}

export function* handleLinkAccounts(payload: {
  type: string;
  data: {
    public_token: string;
    account: AccountType[];
    institution_id: number;
  };
  callback: () => void;
}) {
  try {
    yield call(() => post(API_PLAID_LINK_ACCOUNTS, payload.data));
    payload.callback();
  } catch (err) {
    console.error(err);
  }
}
