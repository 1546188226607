import React from 'react';
import { Switch, HStack } from 'native-base';
import { BasicSwitchFCInterface } from '@ownTypes/InputTypes';

const BasicSwitch: React.FC<BasicSwitchFCInterface> = ({
  size = 'lg',
  children: label,
  onToggle,
  checked = false,
}): JSX.Element => {
  return (
    <HStack>
      <Switch
        size={size}
        value={checked}
        onValueChange={onToggle}
        isChecked={checked}
        testID="switch-button"
      />
      {label}
    </HStack>
  );
};

export default BasicSwitch;
