import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import BaseAlert from '@components/BaseAlert';
import { CustomModalFCInterface } from 'types/ComponentsTypes';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import { primaryColor, h1, darkGrey } from 'assets/styles/app';
import { devicePlatformCheck } from 'helpers/appHelper';

const CustomConfirmationModal: React.FC<CustomModalFCInterface> = ({
  handleClose,
  handleConfirm,
  showAlert,
  title,
  message,
  confirmation,
  icon,
  confirmationButtonText,
  cancellationButtonText,
}) => {
  return (
    <BaseAlert
      title={title}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      showAlert={showAlert}
      confirmButtonName={confirmationButtonText}
      cancelButtonName={cancellationButtonText}
      confirmButtonType="delete"
      testID="custom-confirmation-modal"
    >
      <View style={styles.container}>
        <View style={styles.border}>
          <Icon name={icon} size={h1} color={primaryColor} />
        </View>
        <View style={styles.textGroup}>
          <Text
            allowFontScaling={true}
            adjustsFontSizeToFit={true}
            style={styles.textBody}
          >
            {message}
          </Text>
          <Text style={styles.textConfirmation}>{confirmation}</Text>
        </View>
      </View>
    </BaseAlert>
  );
};

const styles = StyleSheet.create({
  textBody: {
    color: darkGrey,
    lineHeight: 24,
  },

  textConfirmation: {
    color: darkGrey,
    paddingTop: 24,
  },

  container: {
    flexDirection: devicePlatformCheck ? 'column' : 'row',
    alignItems: 'center',
  },

  textGroup: {
    flex: 2,
  },

  border: {
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    height: h1 * 2,
    width: h1 * 2,
    borderRadius: h1,
    borderColor: primaryColor,
    marginRight: 24,
    marginLeft: 22,
    marginVertical: 22,
  },
});

export default CustomConfirmationModal;
