import React from 'react';
import { View, StyleSheet } from 'react-native';
import { TableFCInterface } from '@ownTypes/TableTypes';
import { devicePlatformCheck } from '@helpers/appHelper';

const TableRow: React.FC<TableFCInterface> = ({
  style,
  children: childComponent,
  testID,
}): JSX.Element => {
  return (
    <View style={[style, styles.row]} testID={testID}>
      {childComponent}
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    padding: 10,
    borderBottomWidth: 1,
    borderColor: '#cfd3da',
    height: devicePlatformCheck ? 80 : undefined,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default TableRow;
