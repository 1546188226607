import React from 'react';
import { TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import { h4, defaultGreyColor } from '@styles/app';
import { CloseButtonFCInterface } from '@ownTypes/ButtonTypes';

const CloseButton: React.FC<CloseButtonFCInterface> = ({
  handleClose,
  testID,
}): JSX.Element => {
  return (
    <TouchableOpacity onPress={handleClose} testID={testID}>
      <Icon name="times" size={h4} color={defaultGreyColor} />
    </TouchableOpacity>
  );
};

export default CloseButton;
