import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import {
  smallFontSize,
  h4,
  defaultFontWeight,
  dangerColor,
  defaultGreyColor,
} from '@styles/app';
import BasePrice from '@components/BasePrice';
import PageSubtitle from '@components/PageSubtitle';
import { formatDate } from '@helpers/dateHelper';
import { BalanceInformationFCInterface } from '@ownTypes/RentPayTypes';

const BalanceInformation: React.FC<BalanceInformationFCInterface> = ({
  balance,
  dueDate,
  isBalanceDue,
}): JSX.Element => {
  return (
    <View>
      <PageSubtitle testID="rent-pay-balance-amount">
        Balance Due:
        <BasePrice
          style={[
            styles.dueBalancePrice,
            { color: isBalanceDue ? dangerColor : defaultGreyColor },
          ]}
          price={balance}
        />
      </PageSubtitle>
      {/* changed because of logical assigment opertors error on mobile side in the moment when the balance is <= 0 and the date becomes undefined|NaN */}
      {!!dueDate && (
        <Text style={styles.dueDateWrapper} testID="rent-pay-balance-date">
          Due date:
          <Text style={styles.dueDate}>
            {' '}
            {formatDate(new Date(dueDate), 'MM dd, yyyy')}
          </Text>
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  dueBalancePrice: {
    fontSize: h4,
    fontWeight: defaultFontWeight,
    marginLeft: 5,
  },
  dueDateWrapper: {
    color: defaultGreyColor,
    fontSize: smallFontSize,
  },
  dueDate: {
    fontStyle: 'italic',
  },
});

export default BalanceInformation;
