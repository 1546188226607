import ROUTES from '@navigation/routes';
export default {
  //deep link example "tenantportal://app/error"
  prefixes: ['tenantportal://app'],
  config: {
    screens: {
      [ROUTES.RENT_PAY.name]: ROUTES.RENT_PAY.url,
      [ROUTES.MAKE_PAYMENT.name]: ROUTES.MAKE_PAYMENT.url,
      [ROUTES.AUTOPAY.name]: ROUTES.AUTOPAY.url,
      [ROUTES.LOGIN.name]: ROUTES.LOGIN.url,
      [ROUTES.ERROR_PAGE.name]: {
        path: ROUTES.ERROR_PAGE.url,
      },
      [ROUTES.APP_UPDATE.name]: { path: ROUTES.APP_UPDATE.url },
    },
  },
};
