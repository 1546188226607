import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { defaultGreyColor, smallFontSize } from '@styles/app';
import { BankAccountNumberFCInterface } from 'types/MakePaymentTypes';

const BankAccountNumber: React.FC<BankAccountNumberFCInterface> = ({
  subtype,
  mask,
}) => {
  return (
    <Text style={styles.checkingAccount} testID="account-bank-number">
      {subtype} ****{mask}
    </Text>
  );
};

const styles = StyleSheet.create({
  checkingAccount: {
    textTransform: 'capitalize',
    fontSize: smallFontSize,
    color: defaultGreyColor,
  },
});

export default BankAccountNumber;
