export type SubscriptionUpdatePayloadType = {
  account_id: string;
  withdrawal_offset: string;
  amount_cents: number;
};

export type SubscriptionResponseType = {
  account_id: string;
  amount_cents: number;
  id: number;
  withdrawal_offset: number;
  status: string;
  withdrawal_date: string;
  next_scheduled_date: string;
};

export enum SubscriptionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
