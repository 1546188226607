import React from 'react';
import { ScrollView as RNScrollView} from 'react-native';

const ScrollView = ({ children, ...props }): JSX.Element => (
    <RNScrollView {...props}>
      {children}
    </RNScrollView>
);

export default ScrollView;
