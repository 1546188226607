import { Linking } from 'react-native';

const navigateTo = (url: string): void => {
  Linking.canOpenURL(url).then(supported => {
    if (!supported) {
      return Promise.reject(new Error(`Could not open the url: ${url}`));
    }

    return Linking.openURL(url);
  });
};

export default navigateTo;
