import React from 'react';
import { View } from 'react-native';
import CurrencyInput from '@components/inputs/CurrencyInput';
import { AmountWrapperFCInterface } from '@ownTypes/MakePaymentTypes';
import FormGroup from '@components/FormGroup';

const AmountWrapper: React.FC<AmountWrapperFCInterface> = ({
  amount,
  setPaymentAmount,
  inputErrorMessage,
}) => {
  return (
    <View>
      <FormGroup
        label="Amount"
        errorMessage={inputErrorMessage}
        labelTestId="slide-payment-input-label"
      >
        <CurrencyInput
          placeholder="Balance Due"
          handleOnBlur={setPaymentAmount}
          value={amount}
          testID="slide-payment-input"
        />
      </FormGroup>
    </View>
  );
};

export default AmountWrapper;
