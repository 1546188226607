import React, { useState } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import WelcomeMessage from './WelcomeMessage';
import BalanceWrapper from './BalanceWrapper';
import PaymentHistory from './PaymentHistory';
import CustomerInfo from './CustomerInfo';
import { useDispatch, useSelector } from 'react-redux';
import { getLastBalance, splitName } from '@helpers/appHelper';
import { DispatchType, StoreInterface } from '@ownTypes/StoreType';
import { TransactionType } from '@ownTypes/reducerTypes/PaymentTypes';
import { ACCOUNTS_ACTION_TYPES } from 'store/sagas/actions/AccountsActions';
import { setMakePaymentOverlay } from '@reducers/AppReducer';
import { createAccount } from '@services/plaid';
import { USER_ACTION_TYPES } from '@sagas/actions/UserActions';
import { SUBSCRIPTION_ACTION_TYPES } from '@sagas/actions/SubscriptionActions';
import useCTAButtonByPlatform from 'hooks/useCTAButtonByPlatform';
import { sendEvent, identify } from 'services/segment';
import { RentPayFCInterface } from '@ownTypes/RentPayTypes';
import ROUTES from '@navigation/routes';
import { SEGMENT_EVENTS } from '@ownTypes/SegmentTypes';
import { useModalContext } from '@components/BaseModal';
import PlaidServiceUnavailableModal from '@views/MakePayment/Accounts/Modals/PlaidServiceUnavailableModal';

const RentPayWrapper: React.FC<RentPayFCInterface> = ({
  isScriptLoaded = true,
  setSideModalComponent,
}): JSX.Element => {
  const dispatch: DispatchType = useDispatch();

  const userData = useSelector((state: StoreInterface) => state.user.data);
  const paymentSubscription = useSelector(
    (state: StoreInterface) => state.user.paymentSubscription,
  );
  const payments = useSelector(
    (state: StoreInterface) => state.payments.payments,
  );
  const accounts = useSelector((state: StoreInterface) => state.accounts.list);
  const { showModal, hideModal } = useModalContext();

  const [payment, setPayment] = useState({} as TransactionType);
  const [disablePaymentButton, setDisablePaymentButton] = useState(false);
  const [disableAutopayButton, setDisableAutopayButton] = useState(false);
  const isBalancePaid: boolean = payment?.balance?.cents <= 0;
  const ctaButton = useCTAButtonByPlatform(setSideModalComponent);

  React.useEffect((): void => {
    if (payments && payments.size > 0) {
      const lastBalance: TransactionType = getLastBalance(payments);
      if (lastBalance?.balance !== null) {
        setPayment(lastBalance);
      }
      dispatch({
        type: SUBSCRIPTION_ACTION_TYPES.GET_SUBSCRIPTION,
        payload: { balance: lastBalance?.balance?.cents ?? 0 },
      });
    }
  }, [payments, dispatch]);

  React.useEffect(() => {
    dispatch({
      type: USER_ACTION_TYPES.FETCH_LOGGED_USER,
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (userData.name && userData.id) {
      const { firstname, lastname } = splitName(userData.name);

      identify(userData.uid, {
        firstName: firstname,
        lastName: lastname,
        address: userData.address,
        email: userData.email,
        phone: userData.phone,
        landLord: userData.payee,
      });
    }
  }, [userData]);

  const handleAccountsError = _err => {
    setDisablePaymentButton(false);
    setDisableAutopayButton(false);

    showModal({
      title: 'We are sorry!',
      body: <PlaidServiceUnavailableModal />,
      size: 'sm',
      confirmationButtonTitle: 'Ok',
      handleConfirm: hideModal,
    });
  };

  const checkAccounts = (route?: string) => {
    const disableButton =
      route === ROUTES.AUTOPAY.name
        ? setDisableAutopayButton
        : setDisablePaymentButton;

    if (accounts.size > 0) {
      ctaButton(route);
    } else {
      disableButton(true);

      dispatch({
        type: ACCOUNTS_ACTION_TYPES.FETCH_ACCOUNTS,
        callback: response => {
          if (response.size === 0) {
            dispatch(setMakePaymentOverlay(true));
            createAccount(null, userData?.landlord_uid)
              .then(() => {
                ctaButton(route);
                disableButton(false);
              })
              .catch(() => {
                disableButton(false);
              }).finally(()=> {
                dispatch(setMakePaymentOverlay(false));
              });
          } else {
            ctaButton(route);
            disableButton(false);
          }
        },
        onError: handleAccountsError,
      });
    }
  };

  const handleMakePaymentModal = (): void => {
    sendEvent(SEGMENT_EVENTS.TENANT_PAYMENT_INITIATED, {
      date_payment_initiated: new Date().toISOString(),
    });

    checkAccounts();
  };

  const handleEditAutopay = (): void => {
    checkAccounts(ROUTES.AUTOPAY.name);
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
      {Object.keys(userData).length > 0 && (
        <>
          <CustomerInfo
            address={userData?.address}
            unit={
              userData.property_type !== 'single_family' ? userData.unit : ''
            }
          />
          <WelcomeMessage name={userData?.name} isBalancePaid={isBalancePaid} />
        </>
      )}
      <BalanceWrapper
        disabledPaymentButton={disablePaymentButton || !isScriptLoaded}
        disabledAutopayButton={disableAutopayButton}
        handleMakePayment={handleMakePaymentModal}
        handleEditAutopay={handleEditAutopay}
        payment={payment}
        dueDate={userData.rent_due_date}
        paymentSubscription={paymentSubscription}
      />
      <PaymentHistory />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
});

export default RentPayWrapper;
