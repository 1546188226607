import React from 'react';
import BasePrice from '@components/BasePrice';
import DataTable from '@components/table/Table';
import { StyleSheet } from 'react-native';
import {
  successColor,
  secondaryColor,
  textMargin,
  tableItemStyle,
} from '@assets/styles/app';
import { RECORD_TYPE } from 'types/PaymentTypes';
import { PaymentHistoryTableRowFCInterface } from '@ownTypes/RentPayTypes';

const PaymentHistoryTableRowWrapper: React.FC<PaymentHistoryTableRowFCInterface> =
  ({ payment, children }): JSX.Element => {
    return (
      <DataTable.TableRow testID="rent-pay-ledger-rows">
        {children}
        <DataTable.TableItem
          style={tableItemStyle}
          testID="rent-pay-ledger-cells"
        >
          {payment.record_type === RECORD_TYPE.charge &&
          payment?.amount?.cents ? (
            <BasePrice price={payment.amount.cents} />
          ) : (
            '-'
          )}
        </DataTable.TableItem>
        <DataTable.TableItem
          style={tableItemStyle}
          testID="rent-pay-ledger-cells"
        >
          {payment.record_type === RECORD_TYPE.transaction &&
          payment?.amount?.cents ? (
            <BasePrice price={payment.amount.cents as number} />
          ) : (
            '-'
          )}
        </DataTable.TableItem>
        <DataTable.TableItem
          style={tableItemStyle}
          testID="rent-pay-ledger-cells"
        >
          {payment?.balance?.cents ? (
            <BasePrice
              price={payment.balance.cents as number}
              style={payment.balance.cents < 0 && styles.incomingPrice}
            />
          ) : (
            '-'
          )}
        </DataTable.TableItem>
      </DataTable.TableRow>
    );
  };

const styles = StyleSheet.create({
  incomingPrice: {
    color: successColor,
  },
  pendingText: {
    color: secondaryColor,
    fontStyle: 'italic',
    marginLeft: textMargin,
  },
});

export default PaymentHistoryTableRowWrapper;
