import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { AppStateInterface } from 'types/StateTypes';
import { APIRejectResponse } from 'types/ApiTypes';
import { getBuildNumber, getVersion } from 'react-native-device-info';

const initialState: AppStateInterface = {
  errors: [],
  makePaymentOverlay: false,
  sideModalToggle: false,
  accountsOverlay: false,
  build: Number(getBuildNumber()),
  version: getVersion(),
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppErrors(
      state: WritableDraft<AppStateInterface>,
      action: PayloadAction<APIRejectResponse>,
    ) {
      if (action?.payload) {
        state.errors.push(action.payload);
      }
    },
    setMakePaymentOverlay(
      state: WritableDraft<AppStateInterface>,
      action: PayloadAction<boolean>,
    ) {
      state.makePaymentOverlay = action.payload;
    },
    setSideModalToggle(
      state: WritableDraft<AppStateInterface>,
      action: PayloadAction<boolean>,
    ) {
      state.sideModalToggle = action.payload;
    },
  },
});

export const { setAppErrors, setMakePaymentOverlay, setSideModalToggle } =
  appSlice.actions;

export default appSlice.reducer;
