import { takeLatest, ForkEffect } from 'redux-saga/effects';
import {
  handleFetchAccounts,
  handleDeleteAccount,
} from '@sagas/handlers/AccountsHandler';
import { ACCOUNTS_ACTION_TYPES } from '@sagas/actions/AccountsActions';

export default function* accountsSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(ACCOUNTS_ACTION_TYPES.FETCH_ACCOUNTS, handleFetchAccounts);
  yield takeLatest(ACCOUNTS_ACTION_TYPES.DELETE_ACCOUNT, handleDeleteAccount);
}
