import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { SafeAreaView, StyleSheet } from 'react-native';
import { defaultColor, defaultTheme } from '@styles/app';
import { devicePlatformCheck } from '@helpers/appHelper';
import { NativeBaseProvider } from 'native-base';
import MainNavigator from '@navigation/mainNavigator';
import linkingConfig from '@navigation/linkingConfig';
import { navigationRef } from '@navigation/rootNavigation';
import store from '@store/index';
import theme from '@styles/theme';
import Portal from '@burstware/react-native-portal';
import useAuth0SessionManagement from '@hooks/useAuth0SessionManagement';
import { storeStateService } from 'services/storeStateService';
import { REDUCERS } from 'types/StoreTypes';
import { BaseModalProvider } from '@components/BaseModal';
import { initializeDataDog } from '@services/datadog';

function App() {
  const { setEvent, clearEvent } = useAuth0SessionManagement();
  const [panResponder, setPanResponder] = useState(null);

  React.useEffect(() => {
    let eventSet = null;
    if (!panResponder) {
      storeStateService(
        token => {
          if (token && !eventSet) {
            eventSet = 1;
            setPanResponder(setEvent());
          } else if (!token) {
            clearEvent();
            setPanResponder(null);
          }
        },
        REDUCERS.USER,
        'token',
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavigationContainer
      theme={defaultTheme}
      linking={linkingConfig}
      ref={navigationRef}
      onReady={() => {
        initializeDataDog(navigationRef.current);
      }}
    >
      <SafeAreaView style={styles.container} {...panResponder?.panHandlers}>
        <NativeBaseProvider theme={theme}>
          <Provider store={store}>
            <BaseModalProvider>
              <Portal.Host>
                <SafeAreaView style={styles.content}>
                  <MainNavigator />
                </SafeAreaView>
              </Portal.Host>
            </BaseModalProvider>
          </Provider>
        </NativeBaseProvider>
      </SafeAreaView>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: '100%',
    marginBottom: 10,
  },
  content: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    marginHorizontal: !devicePlatformCheck ? 60 : undefined,
    backgroundColor: defaultColor,
  },
});

export default App;
