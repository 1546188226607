import { RECORD_TYPE } from 'types/PaymentTypes';
import { TransactionType } from '@ownTypes/reducerTypes/PaymentTypes';
import { Platform } from 'react-native';

export const isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(
  navigator.userAgent,
);
export const devicePlatformCheck = Platform.OS !== 'web' || isMobileBrowser;
export const forbiddenCharactes = /[^a-zA-Z/\s\s+/g]{1,}/g;

export const splitName = (
  fullName?: string,
): { firstname: string; lastname: string } => {
  const nameArray = fullName.replace(forbiddenCharactes, '').split(' ');
  const firstname: string = nameArray[0];
  const lastname: string = nameArray.slice(1).join(' ');

  return {
    firstname,
    lastname,
  };
};

export const getLastBalance = (
  payments: Immutable.List<TransactionType>,
): TransactionType => {
  const lastPayment: TransactionType = payments
    .filter(
      payment =>
        payment.balance !== null || payment.record_type === RECORD_TYPE.balance,
    )
    ?.first();

  return lastPayment;
};

export const getDayFromDate = (d: string): number => {
  const day = d.slice(3, 5).replace(/^0+/, '');

  return Number(day) || undefined;
};

export const englishOrdinalSuffix = (d: string): string => {
  const i = getDayFromDate(d);
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }

  return i + 'th';
};

export const minValue = 100;

export const checkInputBalance = (
  value: number,
  defaultBalance?: number | undefined,
  charge?: number | undefined,
) => {
  if (!value || minValue > value) {
    return 'Amount entered must be equal to or greater than $1.';
  } else if (typeof charge !== undefined && value > charge) {
    return 'Amount entered should not exceed the charge amount.';
  } else if (typeof defaultBalance !== undefined && value > defaultBalance) {
    return 'Amount entered should not exceed the current balance.';
  }

  return '';
};
