import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './src/App';
import { IntercomProvider } from 'react-use-intercom';
require('@config/vector_icons');

ReactDOM.render(
  <Auth0Provider
    domain={process.env.AUTH0_DOMAIN}
    clientId={process.env.AUTH0_CLIENT_ID}
    audience={process.env.AUTH0_AUDIENCE}
    useCookiesForTransactions={true}
    useRefreshTokens={true}
  >
    <IntercomProvider
      appId={process.env.INTERCOM_APP_ID}
      autoBoot
      autoBootProps={{
        customAttributes: {
          TenantPortal: 1,
        },
      }}
      initializeDelay={2000}
    >
      <App />
    </IntercomProvider>
  </Auth0Provider>,
  document.getElementById('app'),
);
