import { takeLatest, ForkEffect } from 'redux-saga/effects';
import { fetchLoggedUser } from '@sagas/handlers/UserHandler';
import { USER_ACTION_TYPES } from '@sagas/actions/UserActions';

export default function* userSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(USER_ACTION_TYPES.FETCH_LOGGED_USER, fetchLoggedUser);
}
