import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';
import DefaultLink from '@components/buttons/DefaultLink';
import AccountsWrapper from './AccountsWrapper';
import { createAccount } from '@services/plaid';
import { setMakePaymentOverlay } from '@reducers/AppReducer';
import { AccountsFCInterface } from '@ownTypes/MakePaymentTypes';
import { StoreInterface, DispatchType } from '@ownTypes/StoreType';
import PageSubtitleSecondary from '@components/PageSubtitleSecondary';
import { defaultMarginSmall } from '@assets/styles/app';

const Accounts: React.FC<AccountsFCInterface> = ({
  setAccount,
  setAlert,
  selectedAccountId,
  deleteActiveAccountCallback,
}) => {
  const showAccountsOverlay: boolean = useSelector(
    (state: StoreInterface) => state.app.accountsOverlay,
  );
  const userData = useSelector((state: StoreInterface) => state.user?.data);
  const dispatch: DispatchType = useDispatch();

  const addPaymentHandler = (): void => {
    dispatch(setMakePaymentOverlay(true));
    createAccount(null, userData?.landlord_uid).catch(() => {
      dispatch(setMakePaymentOverlay(false));
    });
  };

  return (
    <View style={styles.container}>
      <PageSubtitleSecondary testID="payment-method-title">
        <Text>Pay With</Text>
      </PageSubtitleSecondary>
      <AccountsWrapper
        setAccount={setAccount}
        setAlert={setAlert}
        selectedAccountId={selectedAccountId}
        deleteActiveAccountCallback={deleteActiveAccountCallback}
      />
      <View style={styles.addPaymentButtonContainer}>
        <DefaultLink
          disabled={showAccountsOverlay}
          loading={showAccountsOverlay}
          title="+ Add Payment Method"
          handlePress={addPaymentHandler}
          testID="slide-payment-add-payment-method"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: defaultMarginSmall,
  },
  addPaymentButtonContainer: {
    alignSelf: 'flex-start',
  },
});

export default Accounts;
