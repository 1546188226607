import { useIntercom } from 'react-use-intercom';
import { useAuth0 } from '@auth0/auth0-react';

const useHandleLogout = (): (() => void) => {
  const { logout } = useAuth0();
  const { shutdown } = useIntercom();

  return () => {
    try {
      shutdown();
      logout({
        returnTo: `${process.env.APP_BASE_URL}`,
        federated: true,
      });
    } catch (err) {
      console.error(err);
    }
  };
};

export default useHandleLogout;
