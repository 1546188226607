import React, { useState } from 'react';
import { Text } from 'react-native';
import { filterCurrency, centsToDollars } from '@helpers/currencyHelper';
import { BasePriceFCInterface } from '@ownTypes/ComponentsTypes';

const BasePrice: React.FC<BasePriceFCInterface> = ({
  style,
  price: basePrice,
  testID,
}): JSX.Element => {
  const [price, setPrice] = useState('0');

  React.useEffect(() => {
    const formattedPrice = filterCurrency(centsToDollars(basePrice));

    setPrice(formattedPrice);
  }, [basePrice]);

  return (
    <Text style={style} adjustsFontSizeToFit={true} testID={testID}>
      {price ? price : '-'}
    </Text>
  );
};

export default BasePrice;
