import { Unsubscribe } from '@redux-saga/core';
import store from 'store';

const NO_STATE_MESSAGE = 'State not found into the store';
const NO_REDUCER_MESSAGE = 'Reducer not found into the store';

export const storeStateService: (
  callback: (arg0: any, arg1: Unsubscribe) => any,
  reducer?: string,
  state?: string,
  keepAlive?: boolean,
) => Promise<string> = (
  callback,
  reducer = '',
  state = '',
  keepAlive = false,
) => {
  const reducers = store.getState?.();
  let defaultState = '';

  if (reducer && reducers?.[reducer]) {
    if (typeof reducers?.[reducer]?.[state] !== 'undefined') {
      defaultState = reducers[reducer][state];
    } else {
      throw new Error(NO_STATE_MESSAGE);
    }
  } else {
    throw new Error(NO_REDUCER_MESSAGE);
  }

  const unsubscribe: Unsubscribe = store.subscribe(() => {
    if (store.getState()[reducer][state] !== defaultState) {
      if (typeof callback === 'function') {
        callback(
          store.getState()[reducer][state],
          keepAlive ? unsubscribe : null,
        );
      }
      defaultState = store.getState()[reducer][state];
    }

    if (!keepAlive) {
      unsubscribe();
    }
  });
};
