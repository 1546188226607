import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import {
  defaultGreyColor,
  defaultMargin,
  defaultSectionPadding,
  successColor,
} from '@styles/app';
import PrimaryButton from 'components/buttons/PrimaryButton';
import DefaultButton from 'components/buttons/DefaultButton';
import Paragraph from 'components/structure/Paragraph';
import { formatDate } from 'helpers/dateHelper';
import { filterCurrency, centsToDollars } from 'helpers/currencyHelper';
import { PaymentConfirmationFCInterface } from 'types/MakePaymentTypes';
import { useDispatch, useSelector } from 'react-redux';
import { StoreInterface } from 'types/StoreType';
import BoldText from 'components/structure/BoldText';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import { useNavigation } from '@react-navigation/native';
import { setLastPaymentResponse } from 'store/reducers/PaymentsReducer';

const PaymentConfirmation: React.FC<PaymentConfirmationFCInterface> = ({
  handleMakeAnotherPayment,
  handleClose,
}): JSX.Element => {
  const {
    payments: { lastPayment, lastPaymentResponse },
    user: { data: userData },
  } = useSelector((state: StoreInterface) => state);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', () => {
      dispatch(setLastPaymentResponse(null));
    });
    return unsubscribe;
  }, [navigation, dispatch]);

  const ctaMakeAnotherPayment = () => {
    if (Platform.OS !== 'web') {
      navigation.goBack();
    } else {
      handleMakeAnotherPayment();
    }
  };

  const ctaClose = () => {
    if (Platform.OS !== 'web') {
      const ROUTES = require('navigation/routes').default;
      navigation.navigate(ROUTES.RENT_PAY.name);
    } else {
      handleClose();
    }
  };

  return (
    <View testID="payment-confirmation-screen">
      <View style={styles.paymentInfoWrapper} testID="payment-confirmation-info">
        <BoldText style={styles.thankYouText}>
          <Icon name="check" /> Thank you for your payment!
        </BoldText>
        <Paragraph>
          The confirmation below verifies that you have authorized{' '}
          <BoldText>{userData?.payee}</BoldText> to initiate an electronic
          payment for your account.
        </Paragraph>
        <Paragraph>Your payment is being processed.</Paragraph>
        <Paragraph>
          Payment Confirmation Number:{' '}
          <BoldText>#{lastPaymentResponse?.payment_id}</BoldText>
        </Paragraph>
        <Paragraph>
          Authorized Payment Amount:{' '}
          <BoldText>
            {filterCurrency(
              centsToDollars(lastPaymentResponse?.amount_cents).toString(),
            )}
          </BoldText>
        </Paragraph>

        <Paragraph>
          Date Authorized:{' '}
          <BoldText>{formatDate(new Date(), 'MM dd, yyyy')}</BoldText>
        </Paragraph>
      </View>
      <View style={styles.buttonsWrapper}>
        {lastPayment?.balance?.cents > 0 && (
          <PrimaryButton
            style={styles.button}
            title="Make Another Payment"
            handlePress={ctaMakeAnotherPayment}
            testID="make-another-payment-button"
          />
        )}
        <DefaultButton title="Close" handlePress={ctaClose} testID="close-payment-confirmation" />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  paymentInfoWrapper: {
    marginVertical: defaultMargin,
    paddingHorizontal: defaultSectionPadding,
    paddingBottom: defaultMargin,
    borderColor: defaultGreyColor,
    borderBottomWidth: 1,
    borderStyle: 'dashed',
  },
  thankYouText: {
    marginBottom: 30,
    color: successColor,
  },
  button: {
    marginRight: defaultMargin,
  },
  buttonsWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default PaymentConfirmation;
